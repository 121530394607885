import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { DownwardArrow, ExpectedLevelLabel, FocusAreaRatingLabelContainer } from './design';

import { COLORS } from '~/styles';

import type { IMultiLangString } from '@learned/types';

const Container = styled.div`
  position: relative;
  height: fit-content;

  & .selected {
    background: ${COLORS.COMPANY};
  }
`;

const Content = styled.div`
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${COLORS.INACTIVE};

  &:hover {
    cursor: pointer;
    background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 60%);
  }
`;

const OpenCircle = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 5px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: solid 1px ${COLORS.WHITE};
`;

const FilledCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${COLORS.WHITE};
`;

const LabelWrapper = styled.div`
  position: absolute;
  text-align: center;
  left: -50px;
  width: 150px;
`;

const Label = styled.span`
  display: inline-block;
  font-size: 12px;
  width: 150px;
  color: ${COLORS.COMPANY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IProps {
  isExpectedLevel?: boolean;
  onChange?: () => void;
  isSelected?: boolean;
  index: number;
  option: {
    label: IMultiLangString;
  };
  options: {
    label: IMultiLangString;
  }[];
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export const FocusAreaLabelPlaceholder = ({
  isExpectedLevel,
  onChange,
  option,
  useMultiLangString,
}: IProps) => {
  const getMultiLangString = useMultiLangString();
  return (
    <FocusAreaRatingLabelContainer $isPlaceholder>
      <ExpectedLevelLabel>
        {isExpectedLevel && (
          <span>
            <Trans>Expected level</Trans>
          </span>
        )}
        {isExpectedLevel && <DownwardArrow />}
      </ExpectedLevelLabel>
      <Container>
        <Content className={isExpectedLevel ? 'selected' : ''} onClick={() => onChange?.()}>
          {isExpectedLevel && (
            <OpenCircle>
              <FilledCircle />
            </OpenCircle>
          )}
        </Content>
        {isExpectedLevel && (
          <LabelWrapper>
            <Label>{`${getMultiLangString(option?.label || {})} `}</Label>
          </LabelWrapper>
        )}
      </Container>
    </FocusAreaRatingLabelContainer>
  );
};
