import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { TSelectedLevel } from '~/pages/ReviewGiveFeedback/types';

import { COLORS } from '~/styles';

import { LevelViewButton } from '../design';

export const FocusAreaPlaceholderContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${COLORS.LABEL_GRAY};
`;

interface IProps {
  openFocusAreaModal?: (selectedFocusArea: TSelectedLevel | null) => void;
}

export const FocusAreaQuestionPlaceholder = ({ openFocusAreaModal }: IProps) => {
  return (
    <FocusAreaPlaceholderContainer>
      <Label>
        <Trans>The expected focus areas for the selected skill level will show here.</Trans>
      </Label>
      <LevelViewButton onClick={openFocusAreaModal}>
        <Trans>View all levels</Trans>
      </LevelViewButton>
    </FocusAreaPlaceholderContainer>
  );
};
