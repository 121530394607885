import React, { ChangeEvent, useState, useEffect, MouseEventHandler } from 'react';

import { ENGAGEMENT_REPORT_CHART_DIMENSIONS, REPORT_TYPES, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICONS } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { isFirst } from '~/pages/Reports/common';
import { Cell } from '~/pages/Reports/Components/Cell';
import CollapseIndicator from '~/pages/Reports/Components/IconWrapper';
import {
  EmptyCell,
  Nester,
  NormalCell,
  NormalCellCtr,
  NotAvailableCtr,
} from '~/pages/Reports/engagement/tabs/CustomStyles';
import { MENU_SIZE, PAGINATION_PRIMARY_OPTIONS } from '~/pages/Reports/options';
import { TColumns, TData, TEngagementData } from '~/pages/Reports/types';

import { ColumnPosition, IColumnTable } from '~/@types/table';
import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import useSearchState from '~/hooks/useSearchState';
import {
  downloadMatrixReport,
  getEngagementDetails,
  TGetEngagementDetailsPayload,
  TSortingOrder,
} from '~/services/reports';
import { COLORS } from '~/styles';
import { getMonthRangeFromDates, processRows } from '~/utils/reports';

import { SearchFieldWrapper } from '../../Participation/PerTeamSection/components/Filters/design';
import { TPartialReviewData } from '../types';

const Wrapper = styled.div`
  margin-top: 40px;
  border-radius: 10px;

  .table-grid-wrapper {
    padding: 24px 32px 16px;
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: ${COLORS.WHITE};
    padding: 20px;
  }

  .filters {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  // Caution: found that this was selectively added only for heatmap
  // but not for table grid so using it here as well.
  & th span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
  }

  & th {
    position: relative;
  }

  .export-csv-button {
    margin-right: 20px;
  }
`;

interface IPerTeamSectionProps {
  viewAs: ROLES.ADMIN | ROLES.COACH | ROLES.USER;
  reviewInfo: TPartialReviewData | undefined;
  onAdvancedReportNavigation: () => void;
}

// RCD/results/TableMatrix
const ResultsTable: React.FC<IPerTeamSectionProps> = ({
  viewAs,
  reviewInfo,
  onAdvancedReportNavigation,
}) => {
  const $search = useSearchState();
  const debouncedSearch = useDebounce($search.value, 1000);
  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);
  const { i18n } = useLingui();

  const [getRatingsDataPayload, setGetRatingsDataPayload] =
    useState<TGetEngagementDetailsPayload>();
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [heatMapColumns, setHeatMapColumns] = useState<TColumns[]>([]);
  const [heatMapData, setHeatMapData] = useState<TEngagementData[]>([]);
  const [columns, setColumns] = useState<IColumnTable<any>[]>([]);
  const { addToast } = useToasts();
  const [sortBy, setSortBy] = useState('');

  const exportCSV = async () => {
    if (!getRatingsDataPayload) {
      return;
    }
    addToast({
      title: i18n._(t`Exporting CSV`),
      subtitle: i18n._(
        t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
      ),
      type: TOAST_TYPES.INFO,
    });

    await downloadMatrixReport(
      {
        ...getRatingsDataPayload,
        // pagination should not take effect for csv, so setting skip and limit to 0
        pagination: {
          skip: 0,
          limit: 0,
        },
      },
      'csv',
      'download',
    );
  };

  useEffect(() => {
    if (!reviewInfo) {
      return;
    }

    const filters = {
      reviews: [reviewInfo.id],
      ...(debouncedSearch &&
        debouncedSearch.trim().length > 0 && { search: debouncedSearch || '' }),
    };

    const sortedArray = sortBy.split('__') || [];

    const getRatingsDataPayload: TGetEngagementDetailsPayload = {
      viewAs,
      reportType: REPORT_TYPES.PERFORMANCE,
      additionalDimensions: [ENGAGEMENT_REPORT_CHART_DIMENSIONS.MEMBER],
      primaryDimension: ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM,
      secondaryDimension: '',
      measure: ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME,
      filters,
      dateRange: getMonthRangeFromDates(
        reviewInfo?.settings.startDate,
        reviewInfo?.settings.endDate,
      ),
      sorting: {
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      },
      options: {
        includeCompanyAverage: true,
      },
      pagination,
    };

    setGetRatingsDataPayload(getRatingsDataPayload);
  }, [debouncedSearch, pagination, reviewInfo, sortBy, viewAs]);

  const [_, isLoading] = useAsyncFetch(
    {
      fetch: async () => {
        if (!getRatingsDataPayload) {
          return;
        }
        try {
          const heatMap = await getEngagementDetails(getRatingsDataPayload);
          const heatmapData = heatMap.data as TData;

          setTotalCount(heatmapData.total || 0);
          setHeatMapColumns(heatmapData.columns);

          const hasSecondaryColumn = heatmapData.columns?.filter(
            (item) => item.id === 'secondaryDimension',
          );
          const heatMapRows: TEngagementData[] = heatmapData.rows.map((item, i, arr) => {
            return {
              ...item,
              showPrimary: hasSecondaryColumn.length === 0 ? true : isFirst(arr, i),
              nestedLevel: 0, // that is what we are using to nest the data
            };
          });

          const processedHeatMapRows: TEngagementData[] = [];

          processRows(heatMapRows, 0, processedHeatMapRows);

          setHeatMapData(processedHeatMapRows);

          return processedHeatMapRows;
        } catch {
          setTotalCount(0);
          setHeatMapColumns([]);
          setHeatMapData([]);
        }
      },
      initialState: [],
    },
    [getRatingsDataPayload],
  );

  useEffect(() => {
    const heatmapColumns = heatMapColumns.map((heatmapColumn) => {
      if (heatmapColumn.id === 'primaryDimension' || heatmapColumn.id === 'secondaryDimension') {
        return {
          ...heatmapColumn,
          id: heatmapColumn.id,
          name: `${heatmapColumn.name}`,
          accessor: `${heatmapColumn.id}`,
          renderCell: (
            cell: TEngagementData,
            onClick: MouseEventHandler<HTMLDivElement> | undefined,
          ) => {
            const secondaryColumnName =
              cell.secondaryName && cell.secondaryName.trim().length > 0 ? cell.secondaryName : '';
            const displayName =
              heatmapColumn.id === 'primaryDimension' ? cell.name : secondaryColumnName;
            return (
              <NormalCellCtr
                className="cell rounded-corners"
                clickable={!!onClick}
                onClick={() => {
                  /* @ts-ignore */
                  onClick ? onClick(cell) : {};
                }}
              >
                <Tooltip tooltip={displayName} size={TOOLTIP_SIZES.BIG}>
                  <NormalCell mWidth={`${MENU_SIZE.LEFT_FULL_WIDTH}px`}>
                    <div className="cell-content-wrapper">
                      <Nester nestLevel={cell.nestedLevel} />
                      <div className={'cell-dimension-section'}>
                        {cell.children && cell.children.length > 0 && (
                          <CollapseIndicator
                            isCollapsed={cell.isCollapsed}
                            handleOnClick={() => {
                              if (cell.isCollapsed) {
                                const newRows = heatMapData.map((row) => {
                                  const isRowAChild =
                                    row.parents.join(',') ===
                                    [...cell.parents, cell.temporalUniqueId].join(',');

                                  if (row.temporalUniqueId === cell.temporalUniqueId) {
                                    return {
                                      ...row,
                                      isCollapsed: false,
                                    };
                                  }

                                  if (isRowAChild) {
                                    return {
                                      ...row,
                                      isVisible: true,
                                    };
                                  }
                                  return row;
                                });

                                setHeatMapData(newRows);
                              } else {
                                // handle collapse
                                const newRows = heatMapData.map((row) => {
                                  const isRowAChild = row.parents
                                    .join(',')
                                    .includes([...cell.parents, cell.temporalUniqueId].join(','));

                                  if (row.temporalUniqueId === cell.temporalUniqueId) {
                                    return {
                                      ...row,
                                      isCollapsed: true,
                                    };
                                  }

                                  if (isRowAChild) {
                                    return {
                                      ...row,
                                      isVisible: false,
                                      isCollapsed: true,
                                    };
                                  }

                                  return row;
                                });

                                setHeatMapData(newRows);
                              }
                            }}
                          />
                        )}
                        <span className="text-content-wrapper">{displayName}</span>
                      </div>
                    </div>
                  </NormalCell>
                </Tooltip>
              </NormalCellCtr>
            );
          },
          isFixed: true,
          centerAlign: true,
          position: ColumnPosition.LEFT,
          maxWidth: heatmapColumn.id === 'primaryDimension' ? '200px' : '100px',
          minWidth: '40px',
          padding: '7px',
          showHeaderTooltip: true,
          sortBy: {
            asc: {
              key: `${heatmapColumn.id}__asc`,
              label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
            },
            desc: {
              key: `${heatmapColumn.id}__desc`,
              label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
            },
          },
        };
      }
      return {
        ...heatmapColumn,
        id: heatmapColumn.id,
        name: `${heatmapColumn.name}`,
        accessor: `${heatmapColumn.id}`,
        // render main table cell (middle table)
        renderCell: (row: TEngagementData) => {
          let rlcEnabled = false;
          // rcd dashboard is a performance graph. so that skipping report type check
          const cellValue = row.cells.find((c) => c.measure === heatmapColumn.id);
          if (cellValue?.rlc) {
            rlcEnabled = true;
          }
          if (!cellValue?.value) {
            if (heatmapColumn.id === 'benchmark') {
              return (
                <Tooltip tooltip={i18n._(t`Not enough data available, please check back later`)}>
                  <EmptyCell className="cell">
                    <NotAvailableCtr>{i18n._(t`N/A`)}</NotAvailableCtr>
                  </EmptyCell>
                </Tooltip>
              );
            }
            return (
              <Tooltip tooltip={i18n._(t`Reporting threshold has not been reached`)}>
                <EmptyCell className="cell">
                  <Icon className="incognito" icon={ICONS.INCOGNITO} fill={COLORS.PLACEHOLDERS} />
                </EmptyCell>
              </Tooltip>
            );
          }

          return (
            <Cell
              value={
                cellValue?.rlc
                  ? (100 / cellValue?.rlc) * cellValue?.value || 0
                  : cellValue?.value || 0
              }
              noColor={false}
            >
              {rlcEnabled ? `${cellValue?.value}/${cellValue?.rlc}` : `${cellValue.value}%`}
            </Cell>
          );
        },
        isFixed: heatmapColumn.id === 'average' || heatmapColumn.id === 'benchmark',
        position: ColumnPosition.RIGHT,
        maxWidth: '20px',
        minWidth: '110px',
        padding: '7px',
        centerAlign: true,
        showHeaderTooltip: true,
        sortBy: {
          asc: {
            key: `${heatmapColumn.id}__desc`,
            label: (i18n: I18n) => i18n._(t`High to low`),
          },
          desc: {
            key: `${heatmapColumn.id}__asc`,
            label: (i18n: I18n) => i18n._(t`Low to high`),
          },
        },
      };
    });
    setColumns(heatmapColumns);
  }, [heatMapData, heatMapColumns, i18n]);

  return (
    <Wrapper>
      <div className="table-grid-wrapper">
        <div className="filters">
          <SearchFieldWrapper
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              $search.set(e.currentTarget.value);
            }}
            value={$search.value}
            placeholder={i18n._(t`Search...`)}
            style={{
              width: '350px',
              borderRadius: '10rem',
              fontSize: '14px',
              fontWeight: 600,
            }}
          />

          <Button
            className="export-csv-button"
            label={t`Export csv`}
            onClick={exportCSV}
            icon={ICONS.EXPORT}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />

          <Button
            label={i18n._(t`Advanced reporting`)}
            color={COLORS.ICONS_PRIMARY}
            onClick={() => {
              onAdvancedReportNavigation();
            }}
            variant={ButtonVariant.SECONDARY}
          />
        </div>
        <TableGrid
          isHeatmapColored={true}
          data={heatMapData?.filter((item) => item.isVisible) || []}
          columns={columns}
          isScrollbarVisible
          showSortBy={false}
          rightMinWidth={`${MENU_SIZE.ONLY_AVERAGE}px`}
          isLoading={isLoading}
          paginationProps={{
            pagination,
            changePagination,
            totalCount,
          }}
          leftMinWidth={`${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`}
          filtersProps={{
            filters: {
              search,
              setSearch: (value) => setSearch(value),
            },
            isToggleHideFilterVisible: false,
            isFiltered: false,
          }}
          setSortBy={(value) => {
            setSortBy(value);
          }}
          showTopArea={false}
          sortBy={''}
          paginationIndexAlternatives={PAGINATION_PRIMARY_OPTIONS.map((item) => ({
            id: item.id,
            label: item.label(i18n),
          }))}
          isLeftColumnsStriped
        />
      </div>
    </Wrapper>
  );
};

export default ResultsTable;
