import { BaseSyntheticEvent, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm, type UseFormReturn } from 'react-hook-form';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import useBoolState from '~/hooks/useBoolState';
import { addSignature } from '~/services/userReviews';

import type { IReviewDashboardUserForm, ISignatureForm } from '../types';

const useSignatures = ({
  userReviewId,
  formMethods,
  isEmployee,
}: {
  userReviewId?: string;
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  isEmployee: boolean;
}) => {
  const { i18n } = useLingui();
  const isVisible = useBoolState(false);
  const [editStepId, setEditStepId] = useState('');
  const { addToast } = useToasts();
  const signatureFormMethods = useForm<ISignatureForm>({
    defaultValues: {
      isSeen: false,
      isAgree: false,
    },
  });

  const role = useMemo(() => {
    return isEmployee ? ROLES.USER : ROLES.COACH;
  }, [isEmployee]);

  const onSubmit = async (e: BaseSyntheticEvent) => {
    if (userReviewId) {
      const submit = signatureFormMethods.handleSubmit(
        async ({ comment, signature, isAgree, isSeen }) => {
          const isConfirmed = await confirm({
            type: CONFIRMATION_MODAL_TYPE.WARNING,
            title: i18n._(t`Warning`),
            description: isEmployee
              ? i18n._(t`You cannot make any changes to your signature and comment.`)
              : i18n._(
                  t`By signing your review the review and all tasks will become locked. In addition, you cannot change your comment.`,
                ),
          });

          if (isConfirmed) {
            isVisible.off();
            const { data } = await addSignature(userReviewId, {
              comment,
              signature,
              signatureDate: new Date().toISOString(),
              userId: formMethods.watch('employee.id'),
              role,
              ...(isEmployee
                ? {
                    isAgree,
                    isSeen,
                  }
                : undefined),
            });
            formMethods.setValue('signatures', data.userReview.signatures);
            formMethods.setValue('status', data.userReview.status);
            addToast({ title: 'Review successfully signed.', type: TOAST_TYPES.SUCCESS });
          }
        },
      );

      return submit(e);
    }
  };

  return {
    isVisible,
    editStepId,
    setEditStepId,
    onSubmit,
    signatureFormMethods,
  };
};

export { useSignatures };
