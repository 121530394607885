import React, { useMemo, useState } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import {
  IconBackground,
  IconContainer,
} from '~/pages/ReviewGiveFeedback/components/Questions/design';
import type { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import type {
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionSkillCategoryGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import {
  CoachCircle,
  FocusAreaHeader,
  Header,
  PeerCircle,
  RatingHeader,
  Scale,
  SelfCircle,
  StyledCareerHeader,
  Table,
  TableRow,
} from './design';
import { SkillAnswersModal } from './SkillAnswersModal';
import { SkillRow } from './SkillRow';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type {
  IJobProfile,
  IReviewRating,
  IReviewTheme,
  IUserReview,
  WithExtends,
} from '@learned/types';

function SkillAnswers({
  onEdit,
  question,
  isZeroState,
  userReview,
  questionNumber,
}: {
  onEdit: (
    skill:
      | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
      | IUserReviewQuestionCustomSkillGrouped['skills'][0],
    question: IQuestionDefaultData,
  ) => void;
  isZeroState: boolean;
  questionNumber: number;
  question: WithExtends<
    IUserReviewQuestionSkillCategoryGrouped | IUserReviewQuestionCustomSkillGrouped,
    { theme?: IReviewTheme }
  >;
  userReview: IUserReview;
}) {
  const getMultiLangString = useMultiLangString();
  const [showCoachesModal, setShowCoachesModal] = useState(false);
  const [showPeersModal, setShowPeersModal] = useState(false);

  const relevantQuestion = question?.skills?.at(0)?.questions?.at(0);
  const options = relevantQuestion?.settings.options;

  const jobNames = useMemo(() => {
    if (!(relevantQuestion && relevantQuestion.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY)) {
      return '';
    }
    const jobs = [getMultiLangString((relevantQuestion.settings.jobProfile as IJobProfile)?.name)];
    if (jobs.length === 1) {
      return jobs[0];
    } else {
      return jobs.join(', ');
    }
  }, [relevantQuestion, getMultiLangString]);

  const totalCoaches = useMemo(() => {
    const ratings = question.skills.flatMap((skill) =>
      skill.questions.flatMap((q) =>
        (q.reviewRatings ?? []).filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH),
      ),
    );
    const uniqueCoaches = new Set(
      ratings.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  const totalPeers = useMemo(() => {
    const ratings = question.skills.flatMap((skill) =>
      skill.questions.flatMap((q) =>
        (q.reviewRatings ?? []).filter(
          (rating) =>
            rating.type === REVIEW_RATING_TYPE.PEER ||
            rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
        ),
      ),
    );
    const uniqueCoaches = new Set(
      ratings.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  const JobNamesAndLabels = (
    <Header $isZeroState={isZeroState}>
      {options && (
        <Scale>
          1 {getMultiLangString(options[0].label)}
          {' - '}
          {options.length} {getMultiLangString(options.at(options.length - 1)!.label)}
        </Scale>
      )}
      {jobNames && (
        <StyledCareerHeader>
          <IconContainer>
            <IconBackground backgroundColor={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY} />
            <Icon
              size={ICON_SIZES.SMALL}
              icon={ICONS.CAREER}
              color={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY}
            />
          </IconContainer>
          <div>{jobNames}</div>
        </StyledCareerHeader>
      )}
    </Header>
  );

  return (
    <>
      {JobNamesAndLabels}
      <Table $isZeroState={isZeroState}>
        <TableRow>
          <FocusAreaHeader>
            <Trans>Behavior and results</Trans>
          </FocusAreaHeader>
          <RatingHeader>
            {!isZeroState && <SelfCircle />}
            <Trans>SELF</Trans>
          </RatingHeader>
          <RatingHeader>
            {!isZeroState && <CoachCircle />}
            <Trans>COACH</Trans>
            {!isZeroState && totalCoaches > 1 && (
              <>
                <span>({totalCoaches})</span>{' '}
                <Icon
                  icon={ICONS.EXTERNAL_LINK}
                  size={ICON_SIZES.SMALL}
                  onClick={() => setShowCoachesModal(true)}
                />
              </>
            )}
          </RatingHeader>
          <RatingHeader>
            {!isZeroState && <PeerCircle />}
            <Trans>PEERS</Trans>
            {!isZeroState && totalPeers > 1 && (
              <>
                <span>({totalPeers})</span>{' '}
                <Icon
                  icon={ICONS.EXTERNAL_LINK}
                  size={ICON_SIZES.SMALL}
                  onClick={() => setShowPeersModal(true)}
                />
              </>
            )}
          </RatingHeader>
        </TableRow>
        {question.skills.map((skill) => (
          <SkillRow
            key={skill.skillId}
            skill={skill}
            userReview={userReview}
            onEdit={(ratings: IReviewRating[]) =>
              onEdit(
                {
                  ...skill,
                  // @ts-ignore
                  reviewRating: ratings,
                  type: relevantQuestion?.type,
                },
                question,
              )
            }
          />
        ))}
      </Table>
      {showCoachesModal && (
        <SkillAnswersModal
          key="coaches"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          userReview={userReview}
          question={question}
          onClose={() => setShowCoachesModal(false)}
          ratingFilter={(rating?: IReviewRating) => rating?.type === REVIEW_RATING_TYPE.COACH}
          jobNamesHeader={JobNamesAndLabels}
          header={
            <>
              <CoachCircle />
              <Trans>COACHES</Trans>
              <span>({totalCoaches})</span>
            </>
          }
        />
      )}
      {showPeersModal && (
        <SkillAnswersModal
          key="peers"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          jobNamesHeader={JobNamesAndLabels}
          userReview={userReview}
          question={question}
          onClose={() => setShowPeersModal(false)}
          ratingFilter={(rating?: IReviewRating) =>
            rating?.type === REVIEW_RATING_TYPE.PEER ||
            rating?.type === REVIEW_RATING_TYPE.PEER_EMAIL
          }
          header={
            <>
              <PeerCircle />
              <Trans>PEERS</Trans>
              <span>({totalPeers})</span>
            </>
          }
        />
      )}
    </>
  );
}

export { SkillAnswers };
