import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { TSelectedLevel } from '~/pages/ReviewGiveFeedback/types';

import { Container, Footer, Header, LabelContainer, Subtitle, Title, TitleWrapper } from './design';
import { FocusAreaLabelPlaceholder } from './FocusAreaLabelPlaceholder';
import { FocusAreaRatingLabel } from './FocusAreaRatingLabel';

import { FocusAreaSection } from '../FocusAreaSection';

interface IProps {
  // Following any type is yet to be determined and will be changed on integration
  options: any;
  selectedLevel: TSelectedLevel;
  title: string;
  description: string;
  onClose: () => void;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export const FocusAreaModal = ({
  title,
  description,
  onClose,
  useMultiLangString,
  options,
}: IProps) => {
  const { i18n } = useLingui();

  const [selectedLevel, setSelectedLevel] = useState<TSelectedLevel | null>(
    options.find((option: any) => option.isExpectedLevel),
  );

  return (
    <Modal
      width={750}
      contentStyles={{ padding: '19px 30px 24px' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      <Header>
        <TitleWrapper>
          <Title>{title}</Title>
          <Button
            type="button"
            variant={ButtonVariant.CLOSE}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
        </TitleWrapper>
        <Subtitle>{description}</Subtitle>
      </Header>
      <Container>
        {
          <LabelContainer>
            {/* Following any type is yet to be determined and will be changed on integration  */}
            {options.length <= 5 ? (
              options.map((option: any, index: number) => {
                index += 1;

                return (
                  <FocusAreaRatingLabel
                    isExpectedLevel={option.isExpectedLevel}
                    option={option}
                    key={index}
                    useMultiLangString={useMultiLangString}
                    onChange={() => setSelectedLevel(option)}
                  />
                );
              })
            ) : options.length > 5 ? (
              <>
                {/* Following any type is yet to be determined and will be changed on integration  */}
                {options.map((option: any, index: number) => {
                  index += 1;

                  if (index === 1) {
                    return (
                      <FocusAreaRatingLabel
                        isExpectedLevel={option.isExpectedLevel}
                        key={index}
                        option={options[0]}
                        useMultiLangString={useMultiLangString}
                        onChange={() => setSelectedLevel(option)}
                      />
                    );
                  } else if (index === options.length) {
                    return (
                      <FocusAreaRatingLabel
                        isExpectedLevel={option.isExpectedLevel}
                        key={index}
                        option={options[options.length - 1]}
                        useMultiLangString={useMultiLangString}
                        onChange={() => setSelectedLevel(option)}
                      />
                    );
                  }
                  return (
                    <FocusAreaLabelPlaceholder
                      isExpectedLevel={options[index - 1].isExpectedLevel}
                      key={index}
                      index={index}
                      options={options}
                      option={options[index - 1]}
                      useMultiLangString={useMultiLangString}
                      onChange={() => setSelectedLevel(options[index - 1])}
                    />
                  );
                })}
              </>
            ) : null}
          </LabelContainer>
        }
        {selectedLevel?.focusAreas.length && (
          <FocusAreaSection level={selectedLevel} useMultiLangString={useMultiLangString} />
        )}
      </Container>

      <Footer>
        <Button
          label={i18n._(t`Close`)}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
      </Footer>
    </Modal>
  );
};
