import React, { useEffect } from 'react';

import { Trans } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';

import { CommentSection, NAOption } from './Components';
import { RatingAnswers } from './Components/RatingAnswers';
import { RatingLabelPlaceholder } from './Components/RatingLabelPlaceholder';
import { RatingLabel } from './Components/RatingQuestionLabel';
import {
  AllAnswers,
  Answers,
  ContentWrapper,
  QuestionHeader,
  QuestionWrapper,
  RatingLabelContainer,
  RatingQuestionLabel,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { IQuestionDefaultData } from '../../types';
import { WithComments } from '../QuestionView';

import type { IUserReviewQuestionRating } from '@learned/types';

interface IQuestionViewTextProps {
  questionData: IUserReviewQuestionRating;
  languageState: ILanguageStateReturn;
  question: IQuestionDefaultData;
  onChange?: (data: {
    questionId: string;
    answer?: number;
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  defaultValues: IQuestionRatingForm;
  canAnswer: boolean;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export interface IQuestionRatingForm extends WithComments {
  answer: string | null;
  isNotApplicable: boolean;
}

export const RatingQuestion = ({
  question,
  questionData,
  defaultValues,
  onChange,
  canAnswer,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
}: IQuestionViewTextProps) => {
  const formMethods = useForm<IQuestionRatingForm>({ defaultValues });
  const { control, watch, setValue } = formMethods;
  const notApplicableWatch = watch('isNotApplicable');
  const $isNotApplicable = useBoolState(notApplicableWatch);
  const displayAnswers = !!(
    question.otherCoachRatings?.length ||
    question.otherPeerRatings?.length ||
    question.otherSelfRating
  );

  useEffect(() => {
    $isNotApplicable.set(notApplicableWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notApplicableWatch]);

  const options = questionData?.settings?.options ?? [];

  return (
    <QuestionWrapper>
      {canAnswer && (
        <>
          <ContentWrapper className={$isNotApplicable.value ? 'disabled' : ''}>
            <QuestionHeader>
              <RatingQuestionLabel hasError={hasError}>
                <Trans>Select your answer</Trans>
              </RatingQuestionLabel>
              {!questionData?.settings?.isAnswerObligated && (
                <Controller
                  name="isNotApplicable"
                  control={control}
                  render={({ field }) => (
                    <NAOption
                      isChecked={field.value}
                      onChange={(value: boolean) => {
                        field.onChange(value);
                        onChange?.({ questionId: questionData.id, isNotApplicable: value });
                      }}
                    />
                  )}
                />
              )}
            </QuestionHeader>
            <RatingLabelContainer>
              <Controller
                name="answer"
                control={control}
                render={({ field }) => (
                  <>
                    {options.length <= 5 ? (
                      options.map((option, index) => {
                        index += 1;
                        const isSelected = !!(field.value && Number(field.value) === index);
                        return (
                          <RatingLabel
                            option={option}
                            key={index}
                            isSelected={isSelected}
                            useMultiLangString={useMultiLangString}
                            onChange={() => {
                              setValue('answer', !isSelected ? index.toString() : null);
                              onChange?.({
                                questionId: questionData.id,
                                answer: !isSelected ? index : -1,
                              });
                            }}
                          />
                        );
                      })
                    ) : options.length > 5 ? (
                      <>
                        {options.map((_, index) => {
                          index += 1;
                          const isSelected = !!(field.value && Number(field.value) === index);
                          if (index === 1) {
                            return (
                              <RatingLabel
                                key={index}
                                option={options[0]}
                                isSelected={isSelected}
                                useMultiLangString={useMultiLangString}
                                onChange={() => {
                                  setValue('answer', !isSelected ? '1' : null);
                                  onChange?.({
                                    questionId: questionData.id,
                                    answer: !isSelected ? index : -1,
                                  });
                                }}
                              />
                            );
                          } else if (index === options.length) {
                            return (
                              <RatingLabel
                                key={index}
                                option={options[options.length - 1]}
                                isSelected={isSelected}
                                useMultiLangString={useMultiLangString}
                                onChange={() => {
                                  setValue('answer', !isSelected ? index.toString() : null);
                                  onChange?.({
                                    questionId: questionData.id,
                                    answer: !isSelected ? index : -1,
                                  });
                                }}
                              />
                            );
                          }
                          return (
                            <RatingLabelPlaceholder
                              key={index}
                              index={index}
                              options={options}
                              option={options[index - 1]}
                              isSelected={isSelected}
                              useMultiLangString={useMultiLangString}
                              onChange={() => {
                                setValue('answer', !isSelected ? index.toString() : null);
                                onChange?.({
                                  questionId: questionData.id,
                                  answer: !isSelected ? index : -1,
                                });
                              }}
                            />
                          );
                        })}
                      </>
                    ) : null}
                  </>
                )}
              />
            </RatingLabelContainer>
          </ContentWrapper>
          {questionData?.settings?.isCommentsAllowed && (
            <CommentSection
              formMethods={formMethods}
              isRequired={!$isNotApplicable.value && questionData?.settings?.isCommentsObligated}
              onChange={(comment) => onChange?.({ questionId: questionData.id, comment })}
              hasError={hasCommentError}
            />
          )}
        </>
      )}
      {showOtherRatings && displayAnswers && (
        <AllAnswers>
          <Trans>All answers</Trans>
          <Answers>
            <RatingAnswers
              question={question}
              questionData={questionData}
              useMultiLangString={useMultiLangString}
            />
          </Answers>
        </AllAnswers>
      )}
    </QuestionWrapper>
  );
};
