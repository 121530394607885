import React, { useMemo, useState } from 'react';

import { REVIEW_RATING_TYPE, USER_REVIEW_STATUS } from '@learned/constants';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import Tooltip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';
import { getGradient } from '~/pages/ReviewGiveFeedback/utils';
import type {
  IUserReviewQuestionSkillCategoryGrouped,
  IUserReviewQuestionCustomSkillGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { AverageRating } from './AverageRating';
import {
  Comment,
  Comments,
  EditButton,
  EditButtonWrapper,
  FocusAreaItem,
  Name,
  RatingItem,
  SkillItem,
  TableRow,
  Arrow,
} from './design';
import { RoleName } from './RoleName';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import type { ISkill, IUser, IUserReview, IReviewRating } from '@learned/types';

function SkillRow({
  skill,
  onEdit,
  userReview,
}: {
  userReview: IUserReview;
  skill:
    | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
    | IUserReviewQuestionCustomSkillGrouped['skills'][0];
  onEdit: (ratings: IReviewRating[]) => void;
}) {
  const getMultiLangString = useMultiLangString();
  const currentUser = useSelector(getUser);

  const [isOpen, setIsOpen] = useState(false);
  const allRatings = useMemo(() => {
    return skill.questions.flatMap((question) => question.reviewRatings ?? []);
  }, [skill]);
  const total = skill.questions?.at(0)?.settings.options.length ?? 0;
  const totalComments = (skill.questions?.at(0)?.reviewRatings ?? [])
    .map((rating) => rating.comment)
    .filter(Boolean).length;

  const isEditable = (userReview: IUserReview, currentUser: IUser, type: REVIEW_RATING_TYPE) => {
    if (type === REVIEW_RATING_TYPE.SELF) {
      return userReview.createdFor === currentUser.id;
    } else if (type === REVIEW_RATING_TYPE.COACH) {
      return userReview.coaches.includes(currentUser.id);
    } else if (type === REVIEW_RATING_TYPE.PEER) {
      return userReview.peers.filter((peer) => peer.value === currentUser.id).length > 0;
    }
  };

  return (
    <>
      <TableRow>
        <SkillItem isOpen={isOpen} onClick={() => setIsOpen((prev) => !prev)}>
          <Icon
            transform={isOpen ? 'rotate(90)' : 'rotate(0)'}
            size={ICON_SIZES.MEDIUM}
            icon={ICONS.NEXT}
            color={isOpen ? COLORS.COMPANY : COLORS.INACTIVE}
          />
          <Tooltip
            tooltip={getMultiLangString(
              (skill.questions.at(0)?.settings?.skill as ISkill)?.name ?? '',
            )}
          >
            <span className="skillName">
              {getMultiLangString((skill.questions.at(0)?.settings?.skill as ISkill)?.name ?? '')}
            </span>
          </Tooltip>
          {totalComments > 0 && (
            <span className="commentCount">
              <Icon size={ICON_SIZES.MEDIUM} icon={ICONS.COMMENT} /> {totalComments}
            </span>
          )}
        </SkillItem>
        <AverageRating
          total={total}
          ratings={allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.SELF)}
          onEdit={
            isEditable(userReview, currentUser, REVIEW_RATING_TYPE.SELF)
              ? () => onEdit(allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.SELF))
              : undefined
          }
        />
        <AverageRating
          total={total}
          ratings={allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH)}
          onEdit={
            isEditable(userReview, currentUser, REVIEW_RATING_TYPE.COACH)
              ? () =>
                  onEdit(allRatings.filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH))
              : undefined
          }
        />
        <AverageRating
          total={total}
          ratings={allRatings.filter(
            (rating) =>
              rating.type === REVIEW_RATING_TYPE.PEER ||
              rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
          )}
          onEdit={
            isEditable(userReview, currentUser, REVIEW_RATING_TYPE.PEER)
              ? () =>
                  onEdit(
                    allRatings.filter(
                      (rating) =>
                        rating.type === REVIEW_RATING_TYPE.PEER ||
                        rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
                    ),
                  )
              : undefined
          }
        />
      </TableRow>
      {isOpen && (
        <>
          {skill.questions.map((question) => {
            const reviewRatings = (question.reviewRatings ?? []).filter(
              (rating) => rating.answer !== undefined && rating.answer !== null,
            );
            const selfRating = question.reviewRatings?.find(
              (rating) => rating.type === REVIEW_RATING_TYPE.SELF,
            )?.answer;
            return (
              <TableRow key={question.id}>
                <FocusAreaItem>
                  {getMultiLangString(question.settings.focusArea.name)}
                </FocusAreaItem>
                <RatingItem
                  gradient={
                    selfRating && Number(selfRating) >= 0
                      ? getGradient(selfRating as number, total)
                      : undefined
                  }
                >
                  {selfRating ?? ' '}
                </RatingItem>
                <AverageRating
                  total={total}
                  ratings={reviewRatings.filter(
                    (rating) => rating.type === REVIEW_RATING_TYPE.COACH,
                  )}
                />
                <AverageRating
                  total={total}
                  ratings={reviewRatings.filter(
                    (rating) =>
                      rating.type === REVIEW_RATING_TYPE.PEER ||
                      rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
                  )}
                />
              </TableRow>
            );
          })}
          <TableRow>
            <Comments>
              {(skill.questions.at(0)?.reviewRatings ?? []).map((rating) => (
                <Comment key={rating.id}>
                  <Name>
                    {rating.createdBy.id ? (
                      <UserAvatar userId={rating.createdBy.id as string} />
                    ) : (
                      <UserAvatar
                        user={
                          {
                            firstName: '',
                            lastName: '',
                            email: rating.createdBy.email as string,
                          } as IUser
                        }
                      />
                    )}{' '}
                    <span className="role">
                      (<RoleName ratingType={rating.type} />)
                    </span>
                  </Name>
                  <EditButtonWrapper>
                    <Arrow />
                    <RickTextView className="comment" html={rating.comment ?? undefined} />
                    {rating.createdBy.id === currentUser.id &&
                      ![
                        USER_REVIEW_STATUS.PUBLISHED,
                        USER_REVIEW_STATUS.SIGNING,
                        USER_REVIEW_STATUS.ARCHIVED,
                      ].includes(userReview.status) && (
                        <EditButton>
                          <Button
                            variant={ButtonVariant.SECONDARY}
                            icon={ICONS.EDIT_PENCIL}
                            label={'edit'}
                          />
                        </EditButton>
                      )}
                  </EditButtonWrapper>
                </Comment>
              ))}
            </Comments>
          </TableRow>
        </>
      )}
    </>
  );
}

export { SkillRow };
