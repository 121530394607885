import React from 'react';

import { CONFIRMATION_MODAL_TYPE, USER_REVIEW_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';

import { List } from './components/List';
import { ListRowPlaceholder } from './components/ListRowPlaceholder';
import { ListRowSignature } from './components/ListRowSignature';
import { Divider, Header, IconWrapper, Reset, Title, Wrapper } from './design';

import { getUser } from '~/selectors/baseGetters';
import { deleteSignatures } from '~/services/userReviews';
import { isNotNil } from '~/utils/typePredicates';

import type { IReviewDashboardUserForm } from '../../types';
import type { IUser, IUserReview } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface ISignaturesSectionProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  isAllowToSign: (userId: IUser['id']) => boolean;
  onSign: () => void;
  userReviewId: IUserReview['id'];
  showResetAll: boolean;
}

const Signatures = ({
  formMethods,
  onSign,
  isAllowToSign,
  userReviewId,
  showResetAll,
}: ISignaturesSectionProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const employee = watch('employee');
  const guests = watch('guests');
  const signatures = watch('signatures');
  const status = watch('status');
  const currentUser = useSelector(getUser);

  const usersToSign = [employee?.id, ...guests].filter(isNotNil);
  const isAllGuestsSigned =
    !isEmpty(guests) &&
    isEmpty(
      difference(
        guests,
        signatures.map((s) => s.userId),
      ),
    );

  const onResetAll = async () => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Warning`),
      description: i18n._(
        t` Resetting all signatures will delete all signatures and will require all guests and the employee to sign the review again. All comments made while signing will also be removed. This action cannot be undone.`,
      ),
    });
    if (isConfirmed) {
      const { code } = await deleteSignatures(userReviewId);
      if (code === 200) {
        formMethods.setValue('signatures', []);
        formMethods.setValue('status', USER_REVIEW_STATUS.ACTIVE);
      }
    }
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          <IconWrapper>
            <Icon icon={ICONS.SIGNATURES} size={ICON_SIZES.SMALL} />
          </IconWrapper>
          <Trans>Signatures</Trans>
        </Title>
      </Header>
      <List>
        {isEmpty(usersToSign) ? (
          <ListRowPlaceholder text={i18n._(t`No guests selected.`)} />
        ) : (
          usersToSign.map((userId, key) => {
            const signature = signatures.find((item) => item.userId === userId);

            return (
              <ListRowSignature
                key={key}
                userId={userId}
                isGuest={guests.includes(userId)}
                isCoach={watch('coaches').includes(userId)}
                isCurrentUser={currentUser.id === userId}
                isAllGuestsSigned={isAllGuestsSigned}
                signature={signature}
                reviewStatus={status}
                isShowButton={isAllowToSign(userId)}
                onClick={onSign}
              />
            );
          })
        )}
      </List>
      {showResetAll && !isEmpty(formMethods.watch('signatures')) && (
        <>
          <Divider />
          <Reset>
            <div className="title">
              <Trans>Remove all signatures and open review again?</Trans>
            </div>
            <div className="divider" />
            <Button
              variant={ButtonVariant.TEXT_DELETE}
              label={i18n._(t`Reset all`)}
              onClick={onResetAll}
            />
          </Reset>
        </>
      )}
    </Wrapper>
  );
};

export { Signatures };
