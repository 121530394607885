import React, { useEffect, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  GOAL_SORT_OPTIONS,
  GOAL_STATUSES,
  GOAL_STATUSES_NEW,
  GOAL_TYPES,
  TASK_STATUS,
  TASK_TYPE,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { ICreateGoal, IGoal, IUser, IUserReviewPopulated, WithComments } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { createGoal, updateGoal, updateGoalPublished } from '~/pages/GoalSetup/actions';

import { CreateGoalModal } from './Components/PlanGoals/CreateGoalModal';
import { EmptyGoalsPlaceholder } from './Components/PlanGoals/EmptyGoalsPlaceholder';
import { GoalDashboardModal } from './Components/PlanGoals/GoalDashboardModal';
import { GoalsGrid } from './Components/PlanGoals/GoalsGrid';
import { ContentWrapper, QuestionHeader, QuestionWrapper, TextLabel } from './design';

import { GOALS_STYLES } from '~/constants/goals';
import useBoolState from '~/hooks/useBoolState';
import { getCurrentGoal } from '~/selectors/currentGoal';
import * as goalsService from '~/services/goals';
import * as currentGoalActions from '~/store/currentGoal/actions';
import { COLORS } from '~/styles';

import type { IPopulatedReviewTask } from '../../types';

export interface IQuestionPlanGoalForm extends WithComments {
  answer: string | null;
  isNotApplicable: boolean;
}

interface IQuestionViewPlanGoalProps {
  onEdit?: () => void;
  isDashboard?: boolean;
  userFrom?: IPopulatedReviewTask['userFrom'];
  userReview?: IUserReviewPopulated;
  currentUser?: IUser;
}

enum WARNING_MODAL_TYPES {
  DELETE = 'delete',
  ARCHIVE = 'archive',
  PUBLISH = 'publish',
}

export const PlanGoalQuestion = ({
  onEdit,
  userFrom,
  isDashboard,
  userReview,
  currentUser,
}: IQuestionViewPlanGoalProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const $refetchGoals = useBoolState(false);
  const $loading = useBoolState(false);
  const $isUpdate = useBoolState(false);
  const $showWarningModal = useBoolState(false);
  const $openCreateGoalModal = useBoolState(false);
  const $openGoalDashboardModal = useBoolState(false);
  const selectedGoal = useSelector(getCurrentGoal);
  const [updatedGoal, setUpdatedGoal] = useState<ICreateGoal | null>(null);
  const [goals, setGoals] = useState<IGoal[] | []>([]);
  const [warningType, setWarningType] = useState<WARNING_MODAL_TYPES | null>(null);

  useEffect(() => {
    const fetchGoals = async () => {
      $loading.on();
      let goals = [];

      const savedGoals = userReview?.goalsPlanned || [];

      if (isDashboard && savedGoals.length > 0) {
        goals = mapGoals(savedGoals);
      } else if (!isDashboard) {
        if (userFrom) {
          const params = {
            types: [GOAL_TYPES.PERSONAL],
            owners: [userFrom],
            isGetProgress: true,
            statuses: [
              GOAL_STATUSES_NEW.TODO,
              GOAL_STATUSES_NEW.IN_PROGRESS,
              GOAL_STATUSES_NEW.PROBLEM,
            ],
            sort: GOAL_SORT_OPTIONS.PROGRESS_A_Z,
            join: ['children'],
          };

          // @ts-ignore
          const response = await goalsService.getGoals(params);

          goals = mapGoals(response?.data);
        }
      }
      setGoals(goals);
      $loading.off();
    };
    fetchGoals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$refetchGoals.value]);

  const mapGoals = (goals: IGoal[]) => {
    return goals.map((goal) => {
      return {
        ...goal,
        // @ts-ignore
        ...GOALS_STYLES[goal.type],
      };
    });
  };

  const handleRowClick = (goal: IGoal) => {
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoal(goal));
    $openGoalDashboardModal.on();
  };

  const handleDelete = (goal: IGoal) => {
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoal(goal));
    setWarningType(WARNING_MODAL_TYPES.DELETE);
    $showWarningModal.on();
  };

  const handleEdit = (goal: IGoal) => {
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoal(goal));
    $isUpdate.on();
    $openCreateGoalModal.on();
    $openGoalDashboardModal.off();
  };

  const handleArchive = (goal: IGoal) => {
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoal(goal));
    setWarningType(WARNING_MODAL_TYPES.ARCHIVE);
    $showWarningModal.on();
  };

  const removeGoal = async () => {
    if (selectedGoal?.id) {
      await goalsService.deleteGoal(selectedGoal?.id);
      addToast({
        title: i18n._(t`Goal deleted`),
        type: TOAST_TYPES.INFO,
      });
    }
    dispatch(currentGoalActions.resetCurrentGoal());
    setWarningType(null);
    $showWarningModal.off();
    $refetchGoals.toggle();
    $openCreateGoalModal.off();
    $openGoalDashboardModal.off();
  };

  const onChangeArchiveStatus = async () => {
    if (selectedGoal?.status === GOAL_STATUSES.ARCHIVED) {
      await goalsService.updateGoalArchiveStatus(selectedGoal.id, false);
      addToast({
        title: i18n._(t`Goal unarchived`),
        type: TOAST_TYPES.INFO,
      });
    } else {
      await goalsService.updateGoalArchiveStatus(selectedGoal?.id, true);
      addToast({
        title: i18n._(t`Goal archived`),
        type: TOAST_TYPES.INFO,
      });
    }
    setWarningType(null);
    $showWarningModal.off();
    $refetchGoals.toggle();
    $openGoalDashboardModal.off();
  };

  const handleNewGoalModalCloseEvent = () => {
    // @ts-ignore
    dispatch(currentGoalActions.resetCurrentGoal());
    $openCreateGoalModal.off();
    $isUpdate.off();
  };

  const showSuccessToast = (isDraft: boolean) => {
    addToast({
      title: isDraft ? i18n._(t`Goal saved as draft`) : i18n._(t`Goal published`),
      type: TOAST_TYPES.INFO,
    });
  };

  const handleCreateGoal = async (goal: ICreateGoal, isDraft: boolean) => {
    await createGoal(goal, !isDraft);
    $refetchGoals.toggle();
    showSuccessToast(isDraft);
    handleNewGoalModalCloseEvent();
  };

  const onUpdatePublishedGoal = async () => {
    if (!updatedGoal) {
      return;
    }
    await updateGoalPublished(updatedGoal);
    addToast({
      title: i18n._(t`Goal saved`),
      type: TOAST_TYPES.INFO,
    });
    setUpdatedGoal(null);
    $showWarningModal.off();
    handleNewGoalModalCloseEvent();
    $refetchGoals.toggle();
  };

  const updatePublishedGoal = (goal: ICreateGoal) => {
    setUpdatedGoal(goal);
    $showWarningModal.on();
    setWarningType(WARNING_MODAL_TYPES.PUBLISH);
  };

  const onUpdateGoal = async (goal: ICreateGoal, isDraft: boolean) => {
    await updateGoal(goal, !isDraft);
    showSuccessToast(isDraft);
    setUpdatedGoal(null);
    handleNewGoalModalCloseEvent();
    $refetchGoals.toggle();
  };

  const showEditButton = () => {
    const ineligibleStatusList = [
      USER_REVIEW_STATUS.SIGNING,
      USER_REVIEW_STATUS.PUBLISHED,
      USER_REVIEW_STATUS.ARCHIVED,
    ];

    const userAndCoachTasks =
      userReview?.tasks
        ?.filter((task) =>
          [TASK_TYPE.REVIEW_SELF_EVALUATE, TASK_TYPE.REVIEW_COACH_EVALUATE].includes(task.type),
        )
        .filter((task) => task.status === TASK_STATUS.COMPLETED) || [];

    return (
      (!isDashboard && goals.length > 0) ||
      (currentUser &&
        isDashboard &&
        userAndCoachTasks.length > 0 &&
        (userReview?.createdFor === currentUser.id ||
          userReview?.coaches.includes(currentUser.id)) &&
        ineligibleStatusList.findIndex((status) => status === userReview?.status) === -1)
    );
  };

  return (
    <QuestionWrapper $isDashboard={isDashboard}>
      <ContentWrapper>
        <>
          <QuestionHeader>
            <TextLabel>
              <Trans>Goals</Trans>
            </TextLabel>
            {showEditButton() && (
              <Button
                label={i18n._(isDashboard ? t`Edit` : t`Create new`)}
                variant={isDashboard ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
                icon={ICONS.EDIT_PENCIL}
                onClick={() => (isDashboard ? onEdit?.() : $openCreateGoalModal.on())}
              />
            )}
          </QuestionHeader>
          <ShowSpinnerIfLoading loading={$loading.value}>
            {goals.length ? (
              <GoalsGrid goals={goals} isLoading={$loading.value} handleRowClick={handleRowClick} />
            ) : (
              <EmptyGoalsPlaceholder
                onCreateNew={$openCreateGoalModal.on}
                isDashboard={isDashboard}
              />
            )}
          </ShowSpinnerIfLoading>
        </>
      </ContentWrapper>
      {$openCreateGoalModal.value && (
        <CreateGoalModal
          isUpdate={$isUpdate.value}
          userFrom={userFrom}
          updateGoal={onUpdateGoal}
          handleDelete={handleDelete}
          onClose={handleNewGoalModalCloseEvent}
          refetchGoals={$refetchGoals.toggle}
          handleCreateGoal={handleCreateGoal}
          updatePublishedGoal={updatePublishedGoal}
        />
      )}
      {$openGoalDashboardModal.value && selectedGoal && (
        <GoalDashboardModal
          goal={selectedGoal}
          onClose={$openGoalDashboardModal.off}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleArchive={handleArchive}
        />
      )}{' '}
      {$showWarningModal.value && (
        <ConfirmationModal
          type={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? CONFIRMATION_MODAL_TYPE.DELETE
              : CONFIRMATION_MODAL_TYPE.WARNING
          }
          description={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? i18n._(t`Are you sure you want to delete this goal?`)
              : warningType === WARNING_MODAL_TYPES.ARCHIVE
              ? i18n._(
                  t`Are you sure you want to archive this goal? It is not possible to update the progress of an archived goal.`,
                )
              : i18n._(t`Are you sure you want to save your changes?`)
          }
          onClose={() => {
            setWarningType(null);
            $showWarningModal.off();
          }}
          onSubmit={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? removeGoal
              : warningType === WARNING_MODAL_TYPES.ARCHIVE
              ? onChangeArchiveStatus
              : onUpdatePublishedGoal
          }
          cancelButtonTextColor={COLORS.TEXT_MAIN}
        />
      )}
    </QuestionWrapper>
  );
};
