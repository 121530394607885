import type { Dispatch, SetStateAction } from 'react';

import { FocusAreaType, SKILL_CATEGORIES_ID } from '@learned/constants';
import { isEmpty } from 'lodash';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import { v4 as uuidv4 } from 'uuid';

import type { ISkillCategoryColumn } from '~/@types/job';
import { COLORS } from '~/styles';

import type { IGeneralForm, ISelectedSkill } from './JobForm/types';
import type { TCell, TCellData, TColumnData, TFamilyData, TMeta, TTableData } from './types';
import type {
  IJobProfile,
  IJobTemplate,
  IMultiLangString,
  ISkill,
  ISkillCategory,
  ISkillTemplate,
  WithReplace,
} from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

export type TItemToMove = TCell | null;
type TDestination = {
  droppableId: string;
  index: number;
};

export const removeFromJobs = (
  jobDataArr: TTableData[],
  draggableId: string,
): [TTableData[], TItemToMove] => {
  let itemToMove: TItemToMove = null;
  const valueRemovedArr = jobDataArr?.map((row) => ({
    ...row,
    levels: row?.levels?.map((level) => ({
      ...level,
      columns: level?.columns?.map((column) => ({
        ...column,
        values: column?.values?.filter((cell) => {
          if (cell?.id === draggableId) {
            itemToMove = cell;
            return false;
          }
          return true;
        }),
      })),
    })),
  }));
  return [valueRemovedArr, itemToMove];
};

const testHandler = (val: TCellData[], itemToMove: TItemToMove, destination: TDestination) => {
  if (itemToMove === null) {
    return val;
  }
  const newArr = val.map((item) => {
    if (destination.droppableId.includes(item.id)) {
      const itemList = [...item.values];
      // replace this with slice
      itemList.splice(destination.index, 0, itemToMove);
      return {
        ...item,
        values: [...itemList],
      };
    }
    return item;
  });
  return newArr;
};

export const addToJobs = (
  jobDataArr: TTableData[],
  itemToMove: TItemToMove,
  destination: TDestination,
): TTableData[] => {
  const addToJobs = jobDataArr?.map((row) => ({
    ...row,
    levels: row?.levels?.map((level) => ({
      ...level,
      columns: testHandler(level.columns, itemToMove, destination),
    })),
  }));
  return addToJobs;
};

export const addToUnassigned = (
  unAssignedArr: TCell[],
  itemToMove: TItemToMove,
  destination: TDestination,
): TCell[] => {
  if (itemToMove === null) {
    return unAssignedArr;
  }
  const newArr = [...unAssignedArr];
  // replace this with slice
  newArr.splice(destination.index, 0, itemToMove);
  return newArr;
};

export const removeFromUnassigned = (
  unAssignedArr: TCell[],
  draggableId: string,
): [TCell[], TItemToMove] => {
  const itemToMove = unAssignedArr?.find((cell) => cell?.id === draggableId) || null;
  return [unAssignedArr?.filter((cell) => cell?.id !== draggableId), itemToMove];
};

export const isUnassignItem = (unAssignedArr: TCell[], draggableId: string) => {
  return unAssignedArr?.find((cell) => cell?.id === draggableId) || null;
};

export const cleanJobs = (jobDataArr: TTableData[]) => {
  const jobDataArrFiltered = jobDataArr.map((row) => {
    const temp = { ...row };
    temp.levels = temp?.levels?.filter((level) => {
      return level.columns.some((column) => column.values.length !== 0);
    });
    const columnCount = temp?.levels?.map((a) => a.columns.length).reduce((a, b) => a + b, 0) || 0;
    return columnCount > 0 ? temp : row;
  });
  return jobDataArrFiltered;
};

const handleLevel = (
  levelNumber: number,
  numberOfCols: number,
  columnData: TFamilyData[],
  val?: TColumnData[],
) => {
  if (!val) {
    return [];
  }
  const newRow = Array.from({ length: numberOfCols }, (_, i) => ({
    id: uuidv4(),
    values: [],
    familyId: columnData[i + 2].id,
  }));

  const newItemList = [
    ...val.slice(0, levelNumber),
    { columns: newRow },
    ...val.slice(levelNumber),
  ];
  return newItemList;
};
export const addJobLevel = (
  jobDataArr: TTableData[],
  rowNumber: number,
  levelNumber: number,
  numberOfCols: number,
  columnData: TFamilyData[],
) => {
  const jobDataArrFiltered = jobDataArr.map((row, i) => {
    if (i !== rowNumber) {
      return row;
    }
    return {
      ...row,
      count: row.count + 1,
      levels: handleLevel(levelNumber, numberOfCols, columnData, row.levels),
    };
  });

  return jobDataArrFiltered;
};

const handleColumn = (colNumber: number, jobFamilyId: string, val?: TColumnData[]) => {
  if (!val) {
    return [];
  }
  const temp = val.map((item) => {
    const newColumnNames = [
      ...item.columns.slice(0, colNumber),
      { id: uuidv4(), values: [], familyId: jobFamilyId },
      ...item.columns.slice(colNumber),
    ];
    return { ...item, columns: newColumnNames };
  });

  return temp;
};

export const addJobFamily = (
  jobDataArr: TTableData[],
  colNumber: number,
  jobFamilyId: string,
): TTableData[] => {
  const jobDataArrFiltered = jobDataArr.map((row) => {
    return {
      ...row,
      levels: handleColumn(colNumber, jobFamilyId, row.levels),
    };
  });
  return jobDataArrFiltered;
};

export const updateJobColors = (
  jobDataArr: TTableData[],
  rowNumber: number,
  meta?: TMeta,
): TTableData[] => {
  const jobDataArrFiltered = jobDataArr.map((row, i) => {
    if (i !== rowNumber) {
      return row;
    }
    return {
      ...row,
      ...(meta?.color && { color: meta.color }),
      ...(meta?.values && { name: meta.values }),
    };
  });
  return jobDataArrFiltered;
};

export const contrastColors = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || [];
  if (result.length < 4) {
    return COLORS.TEXT_HOVER;
  }
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  const textColor = luminance < 128 ? COLORS.WHITE : COLORS.TEXT_HOVER;
  return textColor;
};

export const updateJobFamily = (
  columnNames: IMultiLangString[],
  colNumber: number,
  meta?: TMeta,
): IMultiLangString[] => {
  const jobDataArrFiltered = columnNames.map((names, i) => {
    if (i !== colNumber) {
      return names;
    }
    return meta?.values ? meta?.values : names;
  });
  return jobDataArrFiltered;
};

const updateOrder = (row: TTableData) => ({
  ...row,
  color: row.color || '',
  order: (row.order || 0) + 1,
});

const createNewJobGroup = (
  singleJobGroup: TTableData,
  column: TFamilyData[],
  firstPart: TTableData[],
  secondPart: TTableData[],
) => [
  ...firstPart,
  {
    ...singleJobGroup,
    count: singleJobGroup.levelsTo || 1,
    levels: [
      {
        columns: Array.from({ length: column.length - 2 }, (_, i) => ({
          id: uuidv4(),
          familyId: column[i + 2].id,
          values: [] as TCell[],
        })),
      },
    ],
  },
  ...secondPart,
];

export const addJobGroup = (
  jobDataArr: TTableData[],
  rowNumber: number,
  singleJobGroup: TTableData,
  column: TFamilyData[],
  ascending: boolean,
): TTableData[] => {
  const end = ascending ? rowNumber - 1 : (rowNumber - jobDataArr.length - 1) * -1;
  const firstPart = jobDataArr.slice(0, end).map(updateOrder);
  const secondPart = jobDataArr.slice(end).map(updateOrder);
  const newJobGroup = createNewJobGroup(singleJobGroup, column, firstPart, secondPart);
  const aggregated = newJobGroup.map((a, i) => ({
    ...a,
    order: ascending ? i + 1 : newJobGroup.length - i,
  }));
  return aggregated;
};

export const deleteJobGroup = (
  jobDataArr: TTableData[],
  unAssignedArr: TCell[],
  rowNumber: number,
  ascending: boolean,
): [TTableData[], TCell[], TCell[]] => {
  if (rowNumber >= 0 && rowNumber < jobDataArr.length) {
    const itemToDelete = jobDataArr[rowNumber];

    const unAssigned =
      itemToDelete?.levels?.map((level) => {
        return level.columns.map((column) => column.values);
      }) || [];
    const withoutDeleted = jobDataArr.filter((item) => item.id !== itemToDelete.id);

    const ordered = withoutDeleted.map((row, i) => ({
      ...row,
      order: ascending ? i + 1 : withoutDeleted.length - i,
    }));
    const unAssignedFlattedItems = unAssigned?.flat(2) || [];
    return [
      ordered,
      [...unAssignedArr, ...(unAssignedFlattedItems as TCell[])],
      unAssignedFlattedItems,
    ];
  }
  return [jobDataArr, unAssignedArr, []];
};

export const deleteJobLevel = (
  jobDataArr: TTableData[],
  currentUnassigned: TCell[],
  rowNumber: number,
  levelNumber: number,
  isAscending: boolean,
): [TTableData[], TCell[], TCell[], TCell[]] => {
  if (rowNumber >= 0 && rowNumber < jobDataArr.length) {
    const selectedRow = jobDataArr[rowNumber];
    if (selectedRow.levels && levelNumber >= 0 && levelNumber < selectedRow.levels?.length) {
      const unAssigned = selectedRow.levels[
        isAscending ? levelNumber : selectedRow.levelsTo - levelNumber
      ].columns.map((column) => column.values);
      const levelChanged = selectedRow.levels
        .filter((_, i) => (isAscending ? i > levelNumber : i < levelNumber))
        .map((a) => a.columns.map((b) => b.values))
        .flat(2);
      const levelGroupUpdated = levelChanged.map((a) => ({
        ...a,
        jobLevelGroup: {
          id: a.jobLevelGroup?.id || '',
          level: (a.jobLevelGroup?.level || 0) - 1,
        },
      }));
      const newJobs = jobDataArr.map((row, i) => {
        if (i !== rowNumber) {
          return row;
        }
        const withoutDeleted = selectedRow?.levels?.filter((_, i) => i !== levelNumber) || [];
        const newCount = row.count - 1;
        return {
          ...row,
          count: newCount,
          levelsFrom: 0,
          levelsTo: newCount,
          levels: withoutDeleted,
        };
      });
      const newUnassigned = unAssigned?.flat(2) || [];

      return [newJobs, [...currentUnassigned, ...newUnassigned], newUnassigned, levelGroupUpdated];
    }
  }
  return [jobDataArr, currentUnassigned, [], []];
};

export const deleteColumn = (
  jobDataArr: TTableData[],
  columnNames: TFamilyData[],
  unAssignedArr: TCell[],
  columnNumber: number,
): [TTableData[], TFamilyData[], TCell[], TCell[]] => {
  const unAssigned = jobDataArr
    .map((row) => {
      return row?.levels?.map((level) => level.columns[columnNumber].values || []) || [];
    })
    .flat(2);
  const newJobArr = jobDataArr.map((row) => {
    return {
      ...row,
      levels: row?.levels?.map((level) => {
        return {
          ...level,
          columns: level.columns.filter((_, i) => i !== columnNumber),
        };
      }),
    };
  });
  const newColumnNames = columnNames.filter((_, i) => i !== columnNumber + 2);
  return [newJobArr, newColumnNames, [...unAssignedArr, ...unAssigned], unAssigned];
};

export const getAggregatedLevels = (isCountAvailable: boolean, jobDataArr: TTableData[] = []) => {
  if (isCountAvailable) {
    return jobDataArr.map((a) => a.count || 1);
  }
  return jobDataArr.map((a) => a.levels?.length || 1);
};

export const getOpenedItemsArr = (
  isCountAvailable: boolean,
  jobDataArr: TTableData[] = [],
): number[] => {
  const aggregated = getAggregatedLevels(isCountAvailable, jobDataArr);
  const singleOpenedItems = aggregated
    .map((a, i) => (a === 1 ? i : null))
    .filter((a) => a !== null);
  return singleOpenedItems as number[];
};

export const convertSkillsDataToTable = ({
  skillTemplates,
  skills,
  setSkills,
  formMethods,
  skillCategory,
  setSource,
}: {
  skillTemplates: ISkillTemplate[];
  skills: ISkill[];
  setSkills?: Dispatch<SetStateAction<ISkill[]>>;
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategory?: ISkillCategory;
  setSource?: Dispatch<SetStateAction<ISkillTemplate | ISkill | undefined>>;
}) => {
  const { setValue, watch } = formMethods;
  const result: ISkillCategoryColumn[] = [];

  const { skills: watchSkills = [] } = watch('skills')
    ? watch(`skills.${skillCategory?.id}`)
    : { skills: [] };

  watchSkills?.forEach((skill) => {
    let source: ISkill | ISkillTemplate | undefined;

    if (skill.skillTemplate && !skill.skill) {
      source = skillTemplates.find(({ id }) => id === skill.skillTemplate);
    } else if (skill.skill) {
      source = skills.find(({ id }) => id === skill.skill);
    }

    if (source) {
      const skillRows: { focusArea: IMultiLangString; level: number }[] = [];
      const baseResultItem = {
        id: source.id,
        onDelete: () => {
          if (skillCategory?.id) {
            setValue(
              `skills.${skillCategory?.id}.skills`,
              watchSkills.filter(
                ({ skill, skillTemplate }) => ![skill, skillTemplate].includes(source?.id),
              ),
            );

            setSkills?.((prevState) => prevState.filter((item) => item.id !== source?.id));
          }
        },
        onEdit: () => {
          if (skillCategory?.id) {
            setSource?.(source);
          }
        },
      };

      skill.selectedFocusAreas.forEach((s) => {
        const levelFocusAreas = source?.focusAreas
          ?.filter((fa) => fa.level < (skillCategory?.skillLevels?.length ?? 0))
          .filter(({ level }) => s.level === level)?.[0];

        if (s.type === FocusAreaType.SELECT_ALL) {
          // push all focus area ids
          source?.focusAreas
            .filter((fa) => fa.level < (skillCategory?.skillLevels?.length ?? 0))
            .forEach((item) => {
              if (isEmpty(item?.values)) {
                skillRows.push({
                  focusArea: { en_GB: '' },
                  level: item.level,
                });
              } else {
                item?.values.forEach(({ name }) => {
                  skillRows.push({
                    focusArea: name,
                    level: item.level,
                  });
                });
              }
            });
        } else if (s.type === FocusAreaType.SELECT_LEVEL) {
          if (!isNil(s.level)) {
            if (s.type === FocusAreaType.SELECT_LEVEL && isEmpty(levelFocusAreas?.values)) {
              skillRows.push({
                focusArea: { name: '' },
                level: s.level,
              });
            } else {
              // push all focus area ids for a specific level
              levelFocusAreas?.values.forEach(({ name }) => {
                skillRows.push({
                  focusArea: name,
                  level: s.level!,
                });
              });
            }
          }
        } else if (s.type === FocusAreaType.SELECT_FOCUS_AREA) {
          // push specific focus area ids for a level
          levelFocusAreas?.values
            .filter(({ id }) => s.focusArea?.includes(id))
            .forEach(({ name }) => {
              skillRows.push({
                focusArea: name,
                level: s.level!,
              });
            });
        }
      });

      // push selected rows to the final result
      skillRows.forEach((item, index) => {
        result.push({
          ...baseResultItem,
          // put the name just for first row of skill template
          name: index === 0 && source?.name ? source?.name : null,
          focusArea: item.focusArea,
          level: item?.level + 1,
          totalLevels: skillCategory?.skillLevels.length,
        });
      });
    }
  });

  return result;
};

// push each focus area id separately on SELECT_FOCUS_AREA type
export const convertSkillsTemplateFormToAPIData = (skillCategories: IGeneralForm['skills']) => {
  const result: {
    skill?: string;
    skillTemplate?: string;
    selectedFocusAreas: {
      type: FocusAreaType;
      level?: number;
      focusArea?: string;
    }[];
  }[] = [];

  Object.values(skillCategories).forEach(({ skills }: ISelectedSkill) => {
    const skillTemplates: {
      skill?: string;
      skillTemplate?: string;
      selectedFocusAreas: {
        type: FocusAreaType;
        level?: number;
        focusArea?: string;
      }[];
    }[] = [];

    skills.forEach((skill, index) => {
      skillTemplates.push({
        skill: skill.skill,
        skillTemplate: skill.skillTemplate,
        selectedFocusAreas: [],
      });

      skill.selectedFocusAreas.forEach((s) => {
        if ([FocusAreaType.SELECT_ALL, FocusAreaType.SELECT_LEVEL].includes(s.type)) {
          skillTemplates[index].selectedFocusAreas.push({
            type: s.type,
            level: s.level,
          });
        } else {
          // push each focus area id separately
          s.focusArea?.map((focusAreaId) => {
            skillTemplates[index].selectedFocusAreas.push({
              type: s.type,
              level: s.level,
              focusArea: focusAreaId,
            });
          });
        }
      });
    });

    result.push(...skillTemplates);
  });

  return result;
};

export const convertSkillsTemplateAPIDataToFormData = (
  jobTemplateSkills: IJobTemplate['skills'],
  skillCategories: ISkillCategory[],
) => {
  let result: IGeneralForm['skills'] = {};

  skillCategories.forEach((category) => {
    const skillLevelsLength = category.skillLevels?.length || 0;

    const defaultFocusAreaLevel = [
      SKILL_CATEGORIES_ID.COMPETENCIES,
      SKILL_CATEGORIES_ID.KPIS,
    ].includes(category.type as SKILL_CATEGORIES_ID)
      ? 1
      : skillLevelsLength >= 3
      ? 3
      : skillLevelsLength;

    result = {
      ...result,
      [category.id]: {
        defaultFocusAreaLevel,
        isDefaultFocusAreaLevelEnabled: true,
        skills: [],
      },
    };
  });

  if (!isEmpty(jobTemplateSkills)) {
    skillCategories.forEach(({ id: skillCategoryId, skillCategoryTemplate }) => {
      const skills = jobTemplateSkills.filter((jobTemplateSkill) => {
        const skillTemplate = jobTemplateSkill.skillTemplateData;

        return (
          skillTemplate?.skillCategory &&
          [skillCategoryId, skillCategoryTemplate].includes(skillTemplate?.skillCategory)
        );
      });

      const tempSkillTemplates: {
        skillTemplate: string;
        selectedFocusAreas: {
          type: FocusAreaType;
          level?: number;
          focusArea?: string[];
        }[];
      }[] = [];

      skills.forEach((skill, index) => {
        tempSkillTemplates.push({
          skillTemplate: skill.skillTemplate,
          selectedFocusAreas: [],
        });

        const selectedFocusAreas = groupBy(skill?.selectedFocusAreas, 'level');

        Object.values(selectedFocusAreas).forEach((items, focusAreaIndex) => {
          items.forEach((s) => {
            if (s.type === FocusAreaType.SELECT_ALL) {
              tempSkillTemplates[index].selectedFocusAreas.push({
                type: s.type,
              });
            } else if (s.type === FocusAreaType.SELECT_LEVEL) {
              tempSkillTemplates[index].selectedFocusAreas.push({
                type: s.type,
                level: s?.level,
              });
            } else {
              const focusArea =
                tempSkillTemplates[index].selectedFocusAreas[focusAreaIndex]?.focusArea || [];
              if (s.focusArea) {
                focusArea?.push(s.focusArea);
              }

              tempSkillTemplates[index].selectedFocusAreas[focusAreaIndex] = {
                focusArea,
                level: s.level,
                type: s.type,
              };
            }
          });
        });
      });

      const skillCategory = skillCategories.find((item) => item.id === skillCategoryId);

      if (skillCategory) {
        const skillLevelsLength = skillCategory.skillLevels?.length || 0;
        const defaultFocusAreaLevel = [
          SKILL_CATEGORIES_ID.COMPETENCIES,
          SKILL_CATEGORIES_ID.KPIS,
        ].includes(skillCategory.type as SKILL_CATEGORIES_ID)
          ? 1
          : skillLevelsLength >= 3
          ? 3
          : skillLevelsLength;

        result = {
          ...result,
          [skillCategoryId]: {
            skills: tempSkillTemplates.splice(0, 5),
            defaultFocusAreaLevel,
            isDefaultFocusAreaLevelEnabled: true,
          },
        };
      }
    });
  }

  return result;
};

export const convertSkillsAPIDataToFormData = (
  jobSkills: {
    skill: WithReplace<ISkill, { skillCategory: ISkillCategory }>;
    selectedFocusAreas: IJobProfile['skills'][number]['selectedFocusAreas'];
  }[],
  skillCategories: ISkillCategory[],
) => {
  let result: IGeneralForm['skills'] = {};

  skillCategories.forEach((category) => {
    const skillLevelsLength = category.skillLevels?.length || 0;
    const defaultFocusAreaLevel = [
      SKILL_CATEGORIES_ID.COMPETENCIES,
      SKILL_CATEGORIES_ID.KPIS,
    ].includes(category.type as SKILL_CATEGORIES_ID)
      ? 1
      : skillLevelsLength >= 3
      ? 3
      : skillLevelsLength;

    result = {
      ...result,
      [category.id]: {
        defaultFocusAreaLevel,
        isDefaultFocusAreaLevelEnabled: true,
        skills: [],
      },
    };
  });

  if (!isEmpty(jobSkills)) {
    skillCategories.forEach(({ id: skillCategoryId, skillCategoryTemplate }) => {
      const skills = jobSkills.filter((jobSkill) => {
        return (
          jobSkill?.skill?.skillCategory &&
          [skillCategoryId, skillCategoryTemplate].includes(jobSkill?.skill?.skillCategory.id)
        );
      });

      const tempSkill: {
        skill: string;
        selectedFocusAreas: {
          type: FocusAreaType;
          level?: number;
          focusArea?: string[];
        }[];
      }[] = [];

      skills.forEach((skill, index) => {
        tempSkill.push({
          skill: skill.skill.id,
          selectedFocusAreas: [],
        });

        const selectedFocusAreas = groupBy(skill?.selectedFocusAreas, 'level');

        Object.values(selectedFocusAreas).forEach((items, focusAreaIndex) => {
          items.forEach((s) => {
            if (s.type === FocusAreaType.SELECT_ALL) {
              tempSkill[index].selectedFocusAreas.push({
                type: s.type,
              });
            } else if (s.type === FocusAreaType.SELECT_LEVEL) {
              tempSkill[index].selectedFocusAreas.push({
                type: s.type,
                level: s?.level,
              });
            } else {
              const focusArea =
                tempSkill[index].selectedFocusAreas[focusAreaIndex]?.focusArea || [];
              if (s.focusArea) {
                focusArea?.push(s.focusArea);
              }

              tempSkill[index].selectedFocusAreas[focusAreaIndex] = {
                focusArea,
                level: s.level,
                type: s.type,
              };
            }
          });
        });
      });

      const skillCategory = skillCategories.find((item) => item.id === skillCategoryId);

      if (skillCategory) {
        const skillLevelsLength = skillCategory.skillLevels?.length || 0;

        const defaultFocusAreaLevel = [
          SKILL_CATEGORIES_ID.COMPETENCIES,
          SKILL_CATEGORIES_ID.KPIS,
        ].includes(skillCategory.type as SKILL_CATEGORIES_ID)
          ? 1
          : skillLevelsLength >= 3
          ? 3
          : skillLevelsLength;

        result = {
          ...result,
          [skillCategoryId]: {
            skills: tempSkill,
            defaultFocusAreaLevel,
            isDefaultFocusAreaLevelEnabled: true,
          },
        };
      }
    });
  }

  return result;
};

export const removeEmptyValues = (value: IMultiLangString) => {
  return Object.entries(value).reduce((acc, [key, value]) => {
    if (value === '') {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});
};

export const nameConverter = (name?: IMultiLangString) => {
  if (!name) {
    return '';
  }
  if (!name.en_GB && name.nl_NL !== '') {
    return name.nl_NL;
  }
  if (!name.en_GB && !name.nl_NL) {
    return name.de_DE;
  }
  return name.en_GB;
};

export const sortItOut = (arr: TCell[] | TFamilyData[]) => {
  return arr.sort((a, b) => {
    if (a?.name || b?.name) {
      return -1;
    }
    if (nameConverter(a.name).toLowerCase() < nameConverter(b.name).toLowerCase()) {
      return -1;
    }
    if (nameConverter(a.name).toLowerCase() > nameConverter(b.name).toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

const addMissingTranslationText = (name: IMultiLangString) => {
  const updatedName: IMultiLangString = {};
  Object.keys(name).forEach(
    (key: string) => (updatedName[key] = `Missing translation - ${name[key]}`),
  );
  return updatedName;
};

export const sortUnassignedJobs = (
  arr: TCell[],
  getMultiLangString: (multiLangString: string | Record<string, string>) => string,
  primaryLanguage?: string,
) => {
  const missingTranslationItems: TCell[] = [];
  const availableTranslationItems: TCell[] = [];
  arr.forEach((jobProfile) => {
    if (!(primaryLanguage && jobProfile.name?.[primaryLanguage])) {
      missingTranslationItems.push({
        ...jobProfile,
        name: addMissingTranslationText(jobProfile.name),
      });
    } else {
      availableTranslationItems.push(jobProfile);
    }
  });
  return [
    ...availableTranslationItems.sort((a, b) => {
      if (!(a?.name && b?.name)) {
        return -1;
      }

      return getMultiLangString(a.name)
        .toLowerCase()
        .localeCompare(getMultiLangString(b.name).toLowerCase());
    }),
    ...missingTranslationItems,
  ];
};

export const prepareVirtualJobLevelGroupId = ({ id, level }: { id: string; level: number }) =>
  `${id}-${level}`;

export const getJobLevelGroupId = (id: string) => {
  return id.split('-')[0];
};

export const sortJobLevels = (jobDataArr: TTableData[], ascending: boolean) => {
  return [...jobDataArr].sort((a, b) => {
    if (a.order === undefined || b.order === undefined) {
      return 0;
    }
    return ascending ? a.order - b.order : b.order - a.order;
  });
};

export const getDataPaginated = (
  pagination: { index: number; limit: number; skip: number },
  data: TTableData[],
) => {
  const page = pagination?.index || 1;
  const limit = pagination?.limit || 10;
  return data.slice((page - 1) * limit, page * limit);
};
