import { useCallback, useEffect, useState } from 'react';

import { SKILL_TEMPLATE_SORT_OPTIONS, SKILL_TEMPLATE_STATUS } from '@learned/constants';

import { WatchSkill } from '~/components/Modals/AddSkillModal/types';

import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSkills } from '~/services/skills';
import { listSkillTemplatesSuperAdmin } from '~/services/skillTemplates';

import type { IMultiLangString, ISkill, ISkillTemplate } from '@learned/types';

type UseAddSkillModalProps = {
  skillCategoryId: string;
  skillCategoryTemplateId?: string;
  initSource?: ISkillTemplate | ISkill;
  jobTemplateId?: string;
  jobSkillTemplates?: ISkillTemplate[];
  watchSkills?: WatchSkill[];
  selectedSkills?: (ISkill | ISkillTemplate)[];
};

const useAddSkillModal = ({
  skillCategoryTemplateId,
  skillCategoryId,
  initSource,
  jobSkillTemplates = [],
  watchSkills = [],
  selectedSkills = [],
}: UseAddSkillModalProps) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isSelectLevelAndFocusAreasVisible, setIsSelectLevelAndFocusAreasVisible] = useState(
    !!initSource,
  );
  const search = useDebounce(searchInputValue, 300);

  const getMultiLangString = useMultiLangString();

  const [companySkills, setCompanySkills] = useState<ISkill[]>([]);
  const [templateSkills, setTemplateSkills] = useState<(ISkill | ISkillTemplate)[]>([]);

  const [skillNames, setSkillNames] = useState<(ISkillTemplate | ISkill)[]>();
  const [source, setSource] = useState<ISkillTemplate | ISkill | undefined>(initSource);

  const [allCompanySkills, setAllCompanySkills] = useState<ISkill[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCompanySkills = async () => {
      setLoading(true);
      // get company skills
      const companySkillsRes = await getSkills({
        hideDeleted: true,
        // @ts-ignore
        categories: [skillCategoryId],
      });

      setAllCompanySkills(Object.values(companySkillsRes.data.skills) as ISkill[]);
      setLoading(false);
    };
    fetchCompanySkills();
  }, [skillCategoryId]);

  useEffect(() => {
    const fetchSkills = async () => {
      setTemplateSkills([]);

      // Get skills for this category from company skills
      const companiesFiltered = allCompanySkills.filter(
        (companySkill) =>
          !watchSkills.some(
            (watchSkill) =>
              watchSkill.skill === companySkill.id ||
              (watchSkill.skillTemplate === companySkill.skillTemplate &&
                jobSkillTemplates?.some(
                  (jobTemplate) =>
                    getMultiLangString(companySkill.name) === getMultiLangString(jobTemplate.name),
                )),
          ),
      );

      // Get recommended skills from job template
      if (jobSkillTemplates) {
        const jobTemplatesFiltered: (ISkill | ISkillTemplate)[] = jobSkillTemplates.filter(
          (item) => {
            const templateNameLower = getMultiLangString(item.name).toLowerCase().trim();

            const isSelected = selectedSkills?.some(
              (selectedSkill) =>
                getMultiLangString(selectedSkill.name).toLowerCase().trim() === templateNameLower,
            );

            const isCompanySkill = companiesFiltered.some(
              (companySkill) =>
                getMultiLangString(companySkill.name).toLowerCase().trim() === templateNameLower,
            );

            return item.skillCategory === skillCategoryTemplateId && !isSelected && !isCompanySkill;
          },
        );

        setTemplateSkills(jobTemplatesFiltered);
      }

      setCompanySkills(companiesFiltered);
    };

    fetchSkills();
  }, [
    skillCategoryId,
    skillCategoryTemplateId,
    jobSkillTemplates,
    watchSkills,
    selectedSkills,
    getMultiLangString,
    allCompanySkills,
  ]);

  // Function to convert IMultiLangString to lowercase and trim it
  const getLowerTrimString = useCallback(
    (skillName: IMultiLangString) => getMultiLangString(skillName).toLowerCase().trim(),
    [getMultiLangString],
  );

  // Search Bar Functionality
  useEffect(() => {
    const fetchSearchSkillTemplates = async () => {
      // STEP 1: Search on all company skills
      const searchSkills = companySkills.filter((companySkill) => {
        return getLowerTrimString(companySkill.name).includes(search.toLowerCase().trim());
      });

      // STEP 2: If not found on company skills, search on super admin skills
      if (searchSkills.length) {
        setSkillNames(searchSkills);
      } else {
        const response = await listSkillTemplatesSuperAdmin(
          {
            search,
            categoryId: skillCategoryTemplateId,
            statuses: [SKILL_TEMPLATE_STATUS.PUBLISHED],
          },
          { skip: 0, limit: 20, sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z },
        );

        const searchSkills = (response.data.skillTemplates as ISkillTemplate[]).filter(
          (skillTemplate) =>
            !watchSkills.some((item) => {
              return skillTemplate.id === item.skillTemplate;
            }),
        );

        setSkillNames(searchSkills);
      }
    };

    if (search) {
      fetchSearchSkillTemplates();
    }
  }, [
    allCompanySkills,
    companySkills,
    getLowerTrimString,
    getMultiLangString,
    search,
    skillCategoryId,
    skillCategoryTemplateId,
    watchSkills,
  ]);

  useEffect(() => {
    if (!searchInputValue) {
      setSkillNames(undefined);
    }
  }, [searchInputValue]);

  return {
    skillNames,
    setSearchInputValue,
    searchInputValue,
    companySkills,
    templateSkills,
    isSelectLevelAndFocusAreasVisible,
    setIsSelectLevelAndFocusAreasVisible,
    source,
    setSource,
    loading,
  };
};

export { useAddSkillModal };
