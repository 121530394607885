import React, { useState } from 'react';

import { REVIEW_THEME_STATUS } from '@learned/constants';
import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router';

import DashboardHeader from '~/components/DashboardHeader';
import { PreviewQuestionModal } from '~/components/Modals/PreviewQuestionModal';
import BaseLayout from '~/layouts/BaseLayout';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';

import { HeaderActions } from './HeaderActions';
import { MainInfoBlock } from './MainInfoBlock';
import { QuestionsBlock } from './QuestionsBlock';
import { SubHeader } from './SubHeader';

import routes from '~/constants/routes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { convertQuestionOptions } from '~/utils/questions';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { DASHBOARD_TYPE } from '../constants';
import { DashboardWrapper } from '../design';

import type { PopulatedReviewTheme } from '../types';
import type { IReviewQuestion, IReviewQuestionDefaultData } from '@learned/types';
interface IThemeTemplateDashboard {
  dashboardType: DASHBOARD_TYPE;
  onPublish?: () => Promise<void>;
  showPreview?: () => void;
  onEdit: () => void;
  isLoading: boolean;
  item: PopulatedReviewTheme | PopulatedReviewTemplate | undefined;
  isPublishDisabled?: boolean;
}

const ThemeTemplateDashboard = ({
  dashboardType,
  onPublish,
  item,
  onEdit,
  isLoading,
  isPublishDisabled,
  showPreview,
}: IThemeTemplateDashboard) => {
  const location = useLocation();
  const languageState = useLanguageState(true);
  const { languages } = languageState;
  const [previewQuestion, setPreviewQuestion] = useState<IReviewQuestion | undefined>();
  const itemName = isEmpty(item) ? (
    !isLoading ? (
      <Trans>No data</Trans>
    ) : (
      ''
    )
  ) : (
    (item as PopulatedReviewTheme)?.name[languages[0].locale] || (
      <Trans>No data in selected language</Trans>
    )
  );

  const goBack = () => {
    routes.REVIEWS.go({}, { hash: location.hash.substring(1) });
  };

  return (
    <>
      <DashboardHeader
        title={itemName}
        onBack={goBack}
        height="108px"
        actions={
          <HeaderActions
            isDraft={item?.status === REVIEW_THEME_STATUS.DRAFT}
            onPublish={onPublish}
            onEdit={onEdit}
            isLoading={isLoading}
            languageState={languageState}
            showPreview={showPreview}
            isPublishDisabled={isPublishDisabled}
          />
        }
        subHeader={
          item?.status === REVIEW_THEME_STATUS.DRAFT && <SubHeader dashboardType={dashboardType} />
        }
      />
      <BaseLayout maxWidth="750px" loading={isLoading}>
        {!isEmpty(item) && (
          <DashboardWrapper>
            <MainInfoBlock item={item} dashboardType={dashboardType} locale={languages[0].locale} />
            <QuestionsBlock
              questions={item.questions}
              languageState={languageState}
              dashboardType={dashboardType}
            />
          </DashboardWrapper>
        )}
        {previewQuestion && (
          <PreviewQuestionModal
            defaultValues={{
              name: turnMultiLangIntoArray(previewQuestion.name, languages),
              type: previewQuestion.type,
              options: convertQuestionOptions(previewQuestion, languages),
              settings: {
                isCommentsAllowed:
                  (previewQuestion?.settings as IReviewQuestionDefaultData)?.isCommentsAllowed ??
                  false,
                isCommentsObligated:
                  (previewQuestion?.settings as IReviewQuestionDefaultData)?.isCommentsObligated ??
                  false,
                isMeasurementReversed:
                  (previewQuestion?.settings as IReviewQuestionDefaultData)
                    ?.isMeasurementReversed ?? false,
              },
            }}
            languageState={languageState}
            onClose={() => setPreviewQuestion(undefined)}
            isPreselectedLang
          />
        )}
      </BaseLayout>
    </>
  );
};

export { ThemeTemplateDashboard };
