import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { Block } from '../components/Block';
import { Text, RowWrapper } from '../design';

const ButtonWrapper = styled.div`
  width: 157px; // we use this to position button tooltip in center
`;

import type { IUserReview } from '@learned/types';
interface IConversationDateTimeProps {
  dateOfConversation: IUserReview['dateOfConversation'];
  dateOfConversationEnd: IUserReview['dateOfConversationEnd'];
  guests: IUserReview['guests'];
  isAllowToPlanConversation: boolean;
  onPlanConversation: () => void;
}

const ConversationDateTimeBlock = ({
  dateOfConversation,
  dateOfConversationEnd,
  guests,
  isAllowToPlanConversation,
  onPlanConversation,
}: IConversationDateTimeProps) => {
  const { i18n } = useLingui();

  const getInfo = () => {
    const date = moment(dateOfConversation).format('YYYY-MM-DD');
    const startTime = moment(dateOfConversation).format('HH:mm');
    const endTime = moment(dateOfConversationEnd).format('HH:mm');
    return <Text>{`${date} | ${startTime} - ${endTime}`}</Text>;
  };

  const checkIfDisabled = () => {
    let isDisabled = false;
    let tooltip;

    if (!isAllowToPlanConversation) {
      isDisabled = true;
      tooltip = i18n._(t`Only employee or guests can plan the conversation`);
    } else if (isEmpty(guests)) {
      isDisabled = true;
      tooltip = i18n._(t`No guests selected to plan the conversation with`);
    }

    return { isDisabled, tooltip };
  };

  const { isDisabled, tooltip } = checkIfDisabled();

  return (
    <Block title={i18n._(t`Date & time`)}>
      <RowWrapper>
        {dateOfConversation ? (
          getInfo()
        ) : (
          <ButtonWrapper>
            <Button
              label={i18n._(t`Plan conversation`)}
              size={ButtonSize.MEDIUM}
              onClick={onPlanConversation}
              variant={ButtonVariant.TEXT_PRIMARY}
              disabled={isDisabled}
              tooltip={tooltip}
            />
          </ButtonWrapper>
        )}
      </RowWrapper>
    </Block>
  );
};

export { ConversationDateTimeBlock };
