import React from 'react';

import { REVIEW_QUESTION_EVALUATORS } from '@learned/constants';
import { IReviewQuestion, IReviewQuestionDefaultData } from '@learned/types';
import { IMultiLangString } from '@learned/types/src/multiLangString';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { PreviewQuestionCard } from '~/components/Question/PreviewQuestionCard';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { DASHBOARD_TYPE } from '../constants';
import { MainInfoWrap, QuestionsBlockHeader, QuestionsListWrap } from '../design';

const StyledPreviewQuestionCard = styled(PreviewQuestionCard)`
  margin-bottom: 10px;
  width: auto;
`;

interface IQuestionsBlock {
  questions: (IReviewQuestion & { themeName?: IMultiLangString })[];
  languageState: ILanguageStateReturn;
  dashboardType: DASHBOARD_TYPE;
}

const evaluatorsOrder = [
  REVIEW_QUESTION_EVALUATORS.EMPLOYEE,
  REVIEW_QUESTION_EVALUATORS.PEER,
  REVIEW_QUESTION_EVALUATORS.COACH,
];

const QuestionsBlock = ({ questions, dashboardType, languageState }: IQuestionsBlock) => {
  return (
    <MainInfoWrap>
      <QuestionsBlockHeader>
        <Trans>Questions</Trans>
      </QuestionsBlockHeader>
      <QuestionsListWrap>
        {questions.map((question, idx) => (
          <StyledPreviewQuestionCard
            key={question.id}
            languageState={languageState}
            index={idx}
            type={question.type}
            name={question.name}
            isPreselectedLang
            hideIndex={dashboardType === DASHBOARD_TYPE.THEME}
            themeName={question.themeName}
            evaluators={
              (
                (question.settings as IReviewQuestionDefaultData)
                  ?.evaluators as REVIEW_QUESTION_EVALUATORS[]
              )?.sort((a, b) => evaluatorsOrder.indexOf(a) - evaluatorsOrder.indexOf(b)) ||
              undefined
            }
          />
        ))}
      </QuestionsListWrap>
    </MainInfoWrap>
  );
};

export { QuestionsBlock };
