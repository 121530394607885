import React from 'react';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { getGradient } from '~/pages/ReviewGiveFeedback/utils';

import { RatingItem } from './design';

import type { IReviewRating } from '@learned/types';

function AverageRating({
  ratings,
  total,
  onEdit,
}: {
  ratings: IReviewRating[];
  total: number;
  onEdit?: () => void;
}) {
  const average =
    ratings.length > 0
      ? ratings.reduce((acc, rating) => acc + (rating.answer as number), 0) / ratings.length
      : 0;
  return (
    <RatingItem
      gradient={average && average >= 0 ? getGradient(average as number, total) : undefined}
    >
      {ratings.length > 0
        ? average.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })
        : ' '}
      {onEdit && (
        <Button variant={ButtonVariant.ICON} icon={ICONS.EDIT_PENCIL} onClick={() => onEdit?.()} />
      )}
    </RatingItem>
  );
}

export { AverageRating };
