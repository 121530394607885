import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  REVIEW_QUESTION_TYPES,
  REVIEW_THEME_STATUS,
  ROLES,
} from '@learned/constants';
import { IReviewTheme } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { AutocompleteReviewThemes } from '~/components/AutocompleteFilters';
import { Dropdown } from '~/components/Dropdown';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';
import { LabelWrapper, SubText } from '~/pages/ReviewThemeSetup/design';
import type {
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  ISkillCategory,
} from '~/pages/ReviewThemeSetup/types';
import { handleEnterKeyPressOnFields } from '~/pages/ReviewThemeSetup/utils';

import {
  AnswerTypeWrapper,
  Label,
  Row,
  Wrapper,
  QuestionDescription,
  Button,
  AnswerTypeContainer,
  AddSkillButtonContainer,
  EditThemes,
} from './design';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import routes from '~/constants/routes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getReviewTheme } from '~/services/reviewThemes';
import { COLORS } from '~/styles';

import { CustomSkill, Ratings, SkillCategory } from '../../../Questions';
import { getRatingScale } from '../../utils';
import { Footer } from '../Footer';
import { TextArea } from '../TextArea';

interface IEditableQuestionProps {
  skills?: ISkill[];
  formMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  onDelete?: (id: IQuestionForm['id']) => void;
  onDuplicate?: (id: IQuestionForm['id'], index?: number) => Promise<void>;
  setSelectedSkill?: (skill: ISkill | null) => void;
  setIsSkillModalOpen: (open: boolean) => void;
  selectTheme?: boolean;
  skillCategories: ISkillCategory[];
  selectedSkills?: ISelectedSkill[];
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
  onThemeSelect?: (themeId: string) => void;
  setCurrentStep?: (step: number) => void;
  selectedQuestionToEdit?: IQuestionForm | null;
}

const EditableQuestion = ({
  skills,
  languageState,
  formMethods,
  onDelete,
  onDuplicate,
  skillCategories,
  selectedSkills,
  setSelectedSkill,
  setIsSkillModalOpen,
  selectTheme,
  selectedQuestionToEdit,
  onThemeSelect,
  setCurrentStep,
  setSelectedSkills,
}: IEditableQuestionProps) => {
  const { i18n } = useLingui();
  const {
    watch,
    control,
    register,
    unregister,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  const typeWatch = watch('type');
  const getMultiLangString = useMultiLangString();
  const currentCompany = useSelector(getCurrentCompany);
  const [selectedTheme, setSelectedTheme] = useState<IReviewTheme>();

  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany;

  const ANSWER_TYPES = [
    {
      id: QUESTION_TYPES.RATING,
      key: QUESTION_TYPES.RATING,
      title: i18n._(t`Rating`),
    },
    {
      id: QUESTION_TYPES.TEXT,
      key: QUESTION_TYPES.TEXT,
      title: i18n._(t`Text area`),
    },
    {
      id: QUESTION_TYPES.GOAL_PLAN,
      key: QUESTION_TYPES.GOAL_PLAN,
      title: i18n._(t`Plan goals`),
    },
    {
      id: QUESTION_TYPES.CUSTOM_SKILL,
      key: QUESTION_TYPES.CUSTOM_SKILL,
      title: i18n._(t`Custom skill`),
    },
    {
      id: QUESTION_TYPES.SKILL_CATEGORY,
      key: QUESTION_TYPES.SKILL_CATEGORY,
      title: i18n._(t`Skills`),
    },
  ];

  const answersList: { key: string; value: string; id?: string }[] = [
    ...ANSWER_TYPES.map((answerType) => {
      if (answerType.id === QUESTION_TYPES.SKILL_CATEGORY) {
        return skillCategories.map(({ value, label }) => {
          return {
            value: getMultiLangString(label || ''),
            key: answerType.id,
            id: value,
          };
        });
      }
      return { value: answerType.title, key: answerType.id };
    }).flat(),
  ];

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  useEffect(() => {
    const fetchTheme = async () => {
      const { data } = await getReviewTheme(selectedQuestionToEdit?.theme);

      const reviewTheme: IReviewTheme = data[API_RETURN_FIELDS.REVIEW_THEME];
      setSelectedTheme(reviewTheme);
    };
    if (selectedQuestionToEdit?.theme) {
      onThemeSelect?.(selectedQuestionToEdit.theme);
      fetchTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionToEdit]);

  useEffect(() => {
    setValue('settings.skills', selectedSkills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkills]);

  useEffect(() => {
    const { options: scale, type } = getValues();

    let ratingScale;

    if (scale?.length) {
      ratingScale = scale;
    } else {
      ratingScale = getRatingScale(
        type.key as REVIEW_QUESTION_TYPES,
        ratingLabels,
        skillLabels,
        languageState,
      );
    }

    setValue('options', ratingScale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeWatch]);

  const getAnswerType = (type?: string) => {
    switch (type) {
      case QUESTION_TYPES.RATING:
        return <Ratings formMethods={formMethods} ratingLabels={ratingLabels} />;
      case QUESTION_TYPES.GOAL_PLAN:
        return (
          <QuestionDescription>
            <Trans>
              A question will be generated that allows the participant to set one or multiple goals.
            </Trans>
          </QuestionDescription>
        );
      case QUESTION_TYPES.SKILL_CATEGORY:
        return <SkillCategory formMethods={formMethods} ratingLabels={skillLabels} />;
      case QUESTION_TYPES.CUSTOM_SKILL:
        return (
          <CustomSkill
            formMethods={formMethods}
            selectedSkills={selectedSkills}
            skills={skills}
            skillCategories={skillCategories}
            setSelectedSkill={setSelectedSkill}
            setIsSkillModalOpen={setIsSkillModalOpen}
            setCurrentStep={setCurrentStep}
            setSelectedSkills={setSelectedSkills}
            ratingLabels={skillLabels}
          />
        );
      default:
        return null;
    }
  };

  const onThemeChange = (theme: IReviewTheme) => {
    setSelectedTheme(theme);
    onThemeSelect && onThemeSelect(theme?.id);
  };

  const onAddSkillClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentStep?.(0);
    setIsSkillModalOpen(true);
  };

  return (
    <Wrapper>
      <Row paddingTop={10}>
        {nameFieldArray.fields?.map(({ locale }, index) => (
          <div className="inputWrapper" key={`${locale}-${index}`}>
            <Input
              error={!!formMethods.formState.errors.name?.[index]?.value}
              name={`name.${index}.value`}
              label={index === 0 ? i18n._(t`Question`) : undefined}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={index === 0}
              height="38px"
              placeholder={i18n._(
                t`Example: Are you on track to meet your Key Performance Indicators for this year?`,
              )}
              register={register(`name.${index}.value`, { required: true })}
              leftIcon={
                size(nameFieldArray.fields) > 1
                  ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                  : undefined
              }
              onKeyDownCapture={handleEnterKeyPressOnFields}
            />
          </div>
        ))}
      </Row>
      <Row>
        <LabelWrapper>
          <Label>
            <Trans>Description</Trans>
          </Label>
          <SubText>
            <Trans>(optional)</Trans>
          </SubText>
        </LabelWrapper>
        {descriptionFieldArray.fields?.map(({ locale }, index) => (
          <div className="inputWrapper" key={`${locale}-${index}`}>
            <TextArea
              name={`description.${index}.value`}
              height="120px"
              register={register(`description.${index}.value`)}
              leftIcon={
                size(nameFieldArray.fields) > 1
                  ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                  : undefined
              }
            />
          </div>
        ))}
      </Row>
      <Row>
        <AnswerTypeContainer>
          <div>
            <Label>
              <Trans>Answer type</Trans>
            </Label>
            <Controller
              name="type"
              rules={{ required: true }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <AnswerTypeWrapper>
                  <Dropdown
                    items={answersList}
                    placeholder={i18n._(t`Answer type`)}
                    isSingleSelect
                    onChange={onChange}
                    stringifyItem={(item) => getMultiLangString(item || '')}
                    selectedItem={value}
                  />
                </AnswerTypeWrapper>
              )}
            />
          </div>
          {typeWatch?.key === QUESTION_TYPES.CUSTOM_SKILL && (
            <AddSkillButtonContainer>
              <Button onClick={onAddSkillClick}>
                <Trans>Add skill</Trans>
              </Button>
              {errors.settings?.skills && (
                <span>
                  <Trans>Please select a custom skill</Trans>
                </span>
              )}
            </AddSkillButtonContainer>
          )}
        </AnswerTypeContainer>
      </Row>
      <Row paddingTop={8} paddingBottom={!selectTheme ? 28 : 0}>
        {getAnswerType(typeWatch?.key)}
      </Row>
      {selectTheme && (
        <Row paddingBottom={28}>
          <Label>
            <Trans>Select a theme for this question</Trans>*
          </Label>
          <AutocompleteReviewThemes
            onChange={onThemeChange}
            checkedList={selectedTheme ? [selectedTheme] : []}
            isSingleSelect
            styles={{
              width: '262px',
              border: errors.theme ? `${COLORS.ACCENT_ERROR} 1px solid` : '',
            }}
            status={REVIEW_THEME_STATUS.PUBLISHED}
            // @ts-ignore
            lastItem={
              <EditThemes
                onClick={() => routes.REVIEWS.go({ role: ROLES.USER }, { hash: 'themes' })}
              >
                <Icon icon={ICONS.EDIT_PENCIL} size={ICON_SIZES.SMALL} />
                <span className="title">{i18n._(t`Edit themes`)}</span>
              </EditThemes>
            }
          />
        </Row>
      )}
      {typeWatch && (
        <Footer
          isSettingsVisible={typeWatch.key !== QUESTION_TYPES.GOAL_PLAN}
          isLeftSideVisible={!!selectedQuestionToEdit}
          formMethods={formMethods}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
        />
      )}
    </Wrapper>
  );
};

export { EditableQuestion };
