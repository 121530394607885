import React from 'react';

import { IMultiLangString } from '@learned/types';
import { Trans } from '@lingui/macro';

import { ExpectedLevelLabel, FocusAreaRatingLabelContainer } from './design';

import { RatingLabel } from '../RatingQuestionLabel';

interface IProps {
  option: {
    label: IMultiLangString;
  };
  isExpectedLevel?: boolean;
  isSelected?: boolean;
  onChange?: () => void;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export const FocusAreaRatingLabel = ({
  isExpectedLevel,
  option,
  isSelected,
  onChange,
  useMultiLangString,
}: IProps) => {
  return (
    <FocusAreaRatingLabelContainer>
      <ExpectedLevelLabel>
        {isExpectedLevel && (
          <span>
            <Trans>Expected level</Trans>
          </span>
        )}
      </ExpectedLevelLabel>
      <RatingLabel
        option={option}
        isSelected={isSelected}
        onChange={onChange}
        useMultiLangString={useMultiLangString}
      />
    </FocusAreaRatingLabelContainer>
  );
};
