import React, { useEffect, useMemo, useState } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui, withI18n } from '@lingui/react';
import _ from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Loader } from '~/components/Buttons/components/Loader';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';

import ControlPanel from './ControlPanel';
import { EngagementReportContext } from './EngagementContext';
import { LoaderCtr } from './LayoutStyles';
import Custom from './tabs/Custom';
import Overtime from './tabs/Overtime';
import Total from './tabs/Total';

import routes from '~/constants/routes';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { usePagination } from '~/hooks/usePagination';
import { getSelectedRole } from '~/selectors/baseGetters';
import {
  getTabDetails,
  ITabConfigurationResponse,
  getFacets,
  TSortingOrder,
  TVisualType,
} from '~/services/reports';
import history from '~/utils/history';

import {
  MONTH_OPTIONS,
  PAGINATION_PRIMARY_OPTIONS,
  PRIMARY_OPTIONS,
  SECONDARY_OPTIONS,
  MEASURE_OPTIONS,
  ADDITIONAL_OPTIONS,
} from '../options';
import { EOptions, TDropDownValues, TOption, EExportOptions, TViewAs, TOptionMenu } from '../types';

function removeIndex(arr: any[], index: number) {
  if (index > arr.length) {
    return arr;
  }
  return arr.slice(0, index).concat(arr.slice(index + 1));
}

type TOptionData = {
  id: string;
  local: {
    name: string;
    description?: string;
  };
}[];

const handleOptionInit = (opArr: TOptionData) => {
  const opTempArr = opArr.map((item) => ({
    key: item.id,
    title: (_i18n: I18n) => item.local.name,
    description: (_i18n: I18n) => item.local.description,
  }));
  return opTempArr;
};

const filterChangeHandler = (OptionArr: TOption[], exclusionsList: (string | undefined)[]) => {
  exclusionsList = exclusionsList.filter((item) => !!item);
  return _.differenceBy(
    OptionArr,
    exclusionsList.map((key) => ({ key })),
    'key',
  );
};

const getViewAs = (
  currentRole: TViewAs,
  pathName: string,
  params: { reportId?: string; reportType?: string },
): TViewAs => {
  let viewAs = currentRole as TViewAs;
  if (
    pathName ===
    routes.REPORTS_COACH_CUSTOM_REPORT.build(
      { role: ROLES.USER },
      { reportId: params.reportId, reportType: params.reportType },
    )
  ) {
    viewAs = ROLES.COACH;
  }
  return viewAs;
};

const getNewlyUtilizedDimensions = (
  dimensionKeys: string[],
  alreadyUtilizedDimensionKeys: string[],
) => {
  return [
    ...alreadyUtilizedDimensionKeys,
    ...dimensionKeys.filter(
      (key) => !!key && !alreadyUtilizedDimensionKeys.includes(key) && !key.includes('_none'),
    ),
  ];
};

const EngagementReportPage = () => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const { reportId, reportType } = params;
  const currentReportType = reportType as REPORT_TYPES;
  const currentRole = useSelector(getSelectedRole);
  const location = useLocation();

  const [showMenu, setShowMenu] = useLocalStorage(LS_KEYS.LS_REPORTS_CONTROL_PANEL_IS_OPEN, false);

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<REPORT_CHART_TYPES | null>(null);
  const [enabledOptions, setEnabledOptions] = useState<EOptions[]>([]);
  const [exportOptionArr, setExportOptionArr] = useState<EExportOptions[]>([]);

  const [tabConfiguration, setTabConfiguration] = useState<ITabConfigurationResponse | null>(null);

  // this is where we store what options from the selection data is possible to store
  const [primaryOptions, setPrimaryOptions] = useState<TOption[]>([...PRIMARY_OPTIONS]);
  const [secondaryOptions, setSecondaryOptions] = useState<TOption[]>([...SECONDARY_OPTIONS]);
  const [secondDimensionOptions, setSecondDimensionOptions] = useState<TOption[]>([]);
  const [thirdDimensionOptions, setThirdDimensionOptions] = useState<TOption[]>([]);
  const [fourthDimensionOptions, setFourthDimensionOptions] = useState<TOption[]>([]);
  const [measureOptions, setMeasureOptions] = useState<TOption[]>([...MEASURE_OPTIONS]);

  // this is where we store the api decisions of fields for the dropdowns
  const [primarySelectionData, setPrimarySelectionData] = useState<TOption[]>([]);
  const [secondarySelectionData, setSecondarySelectionData] = useState<TOption[]>([]);
  const [additionalDimensionSelectionData, setAdditionalDimensionSelectionData] = useState<
    TOption[]
  >([]);

  const [measureSelectionData, setMeasureSelectionData] = useState<TOption[]>([]);

  const [teamsOptions, setTeamsOptions] = useState<TOption[]>([]);
  const [themesOptions, setThemesOptions] = useState<TOption[]>([]);
  const [learnedThemesOptions, setLearnedThemesOptions] = useState<TOption[]>([]);
  const [surveysOptions, setSurveysOptions] = useState<TOption[]>([]);
  const [reviewsOptions, setReviewsOptions] = useState<TOption[]>([]);
  const [jobsOptions, setJobOptions] = useState<TOption[]>([]);
  const [jobGroupOptions, setJobGroupOptions] = useState<TOption[]>([]);
  const [genderOptions, setGenderOptions] = useState<TOption[]>([]);
  const [skillOptions, setSkillOptions] = useState<TOption[]>([]);
  const [skillCategoryOptions, setSkillCategoryOptions] = useState<TOption[]>([]);
  const [performanceCategoryOptions, setPerformanceCategoryOptions] = useState<TOption[]>([]);
  const [memberOptions, setMemberOptions] = useState<TOption[]>([]);
  const [ageGroupOptions, setAgeGroupOptions] = useState<TOption[]>([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState<TOption[]>([]);

  const [primaryRowSelected, setPrimaryRowSelected] = useState<TOption | null>(null);
  const [secondaryRowSelected, setSecondaryRowSelected] = useState<TOption | null>(null);
  const [secondDimensionRowSelected, setSecondDimensionRowSelected] = useState<TOption | null>(
    null,
  );
  const [thirdDimensionRowSelected, setThirdDimensionRowSelected] = useState<TOption | null>(null);
  const [fourthDimensionRowSelected, setFourthDimensionRowSelected] = useState<TOption | null>(
    null,
  );
  const [measureRowSelected, setMeasureRowSelected] = useState<TOption | null>(null);

  const [isHeatmapSelected, setIsHeatmapSelected] = useState(true);
  const [companyAverageSelected, setCompanyAverageSelected] = useState(false);
  const [teamAverageSelected, setTeamAverageSelected] = useState(false);
  const [peerReviewAverageSelected, setPeerReviewAverageSelected] = useState(false);
  const [selfReviewAverageSelected, setSelfReviewAverageSelected] = useState(false);
  const [industryBenchMarkSelected, setIndustryBenchMarkSelected] = useState(false);

  const [monthSelected, setMonthSelected] = useState<string>(MONTH_OPTIONS[0].key);
  const [themesOptionSelected, setThemesOptionSelected] = useState<TOption[]>([]);
  const [teamsOptionSelected, setTeamsOptionSelected] = useState<TOption[]>([]);
  const [surveysOptionSelected, setSurveysOptionSelected] = useState<TOption[]>([]);
  const [reviewsOptionSelected, setReviewsOptionSelected] = useState<TOption[]>([]);
  const [jobsSelected, setJobsOptionSelected] = useState<TOption[]>([]);
  const [jobsGroupsSelected, setJobGroupsSelected] = useState<TOption[]>([]);
  const [gendersSelected, setGendersSelected] = useState<TOption[]>([]);
  const [ageGroupSelected, setAgeGroupSelected] = useState<TOption[]>([]);
  const [educationLevelsSelected, setEducationLevelsSelected] = useState<TOption[]>([]);
  const [skillOptionsSelected, setSkillOptionsSelected] = useState<TOption[]>([]);
  const [skillCategoryOptionsSelected, setSkillCategoryOptionsSelected] = useState<TOption[]>([]);
  const [performanceCategoryOptionsSelected, setPerformanceCategoryOptionsSelected] = useState<
    TOption[]
  >([]);
  const [memberOptionsSelected, setMemberOptionsSelected] = useState<TOption[]>([]);
  const [visualTypes, setVisualTypes] = useState<{ [key: string]: TVisualType }>({});

  const [sorting, setSorting] = useState<
    | {
        order: TSortingOrder;
        orderBy: string;
      }
    | {}
  >({});

  // this will hold down all the dimensions that currently in use,
  const [utilizedDimensions, setUtilizedDimensions] = useState<string[]>([]);

  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);

  const optionMenu: TOptionMenu = {
    primary: primaryRowSelected,
    secondary: secondaryRowSelected,
    secondDimension: secondDimensionRowSelected,
    thirdDimension: thirdDimensionRowSelected,
    fourthDimension: fourthDimensionRowSelected,
    measure: measureRowSelected,

    includeBenchmark: industryBenchMarkSelected,
    includeCompanyAverage: companyAverageSelected,
    includeTeamAverage: teamAverageSelected,
    includePeerReviewAverage: peerReviewAverageSelected,
    includeSelfReviewAverage: selfReviewAverageSelected,
    isHeatmapColored: isHeatmapSelected,

    primaryOptions,
    secondaryOptions,
    secondDimensionOptions,
    thirdDimensionOptions,
    fourthDimensionOptions,
    measureOptions,
    surveysOptions,
    reviewsOptions,
    themesOptions,
    teamsOptions,
    monthOptions: MONTH_OPTIONS,
    jobsOptions,
    jobGroupOptions,
    genderOptions,
    ageGroupOptions,
    educationLevelOptions,
    skillOptions,
    skillCategoryOptions,
    performanceCategoryOptions,
    memberOptions,

    monthSelected,
    themesOptionSelected,
    teamsOptionSelected,
    surveysOptionSelected,
    reviewsOptionSelected,
    jobsSelected,
    jobsGroupsSelected,
    gendersSelected,
    ageGroupSelected,
    educationLevelsSelected,
    skillOptionsSelected,
    skillCategoryOptionsSelected,
    performanceCategoryOptionsSelected,
    memberOptionsSelected,
  };

  const { addToast } = useToasts();
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { team, survey, member, review } = query;

  const resetSkip = {
    index: 1,
    skip: 0,
    limit: pagination.limit,
  };

  const dimensionSelected = {
    primary: primaryRowSelected,
    secondary: secondaryRowSelected,
    measure: measureRowSelected,
  };

  const onDimensionOptionChange = (
    value: string,
    dimension: 'primary' | 'secondary' | 'measure' | 'second' | 'third' | 'fourth',
  ) => {
    const dimensionVsOptions = {
      primary: primaryOptions,
      secondary: secondaryOptions,
      second: secondDimensionOptions,
      third: thirdDimensionOptions,
      fourth: fourthDimensionOptions,
      measure: measureOptions,
    };

    const selectedOption = dimensionVsOptions[dimension].find((option) => option.key === value);

    if (!selectedOption) {
      return;
    }

    // selected item should be disappear from the current options.
    let tempUtilizedDimensions = getNewlyUtilizedDimensions(
      [selectedOption.key],
      utilizedDimensions,
    );
    setUtilizedDimensions(tempUtilizedDimensions);

    switch (dimension) {
      case 'primary': {
        setPrimaryRowSelected(selectedOption);
        setSecondaryRowSelected(null);
        setMeasureRowSelected(null);
        // existing key should be freed up,
        tempUtilizedDimensions = tempUtilizedDimensions.filter(
          (item) => item !== primaryRowSelected?.key,
        );
        setUtilizedDimensions(tempUtilizedDimensions);
        break;
      }
      case 'secondary': {
        setSecondaryRowSelected(selectedOption);
        // existing key should be freed up,
        tempUtilizedDimensions = tempUtilizedDimensions.filter(
          (item) => item !== secondaryRowSelected?.key,
        );
        setUtilizedDimensions(tempUtilizedDimensions);
        break;
      }
      case 'measure': {
        setMeasureRowSelected(selectedOption);
        setSecondaryRowSelected(null);
        // existing key should be freed up,
        tempUtilizedDimensions = tempUtilizedDimensions.filter(
          (item) => item !== measureRowSelected?.key,
        );
        setUtilizedDimensions(tempUtilizedDimensions);
        break;
      }
      case 'second': {
        setSecondDimensionRowSelected(selectedOption);
        if (selectedOption.key === 'additional_none') {
          tempUtilizedDimensions = tempUtilizedDimensions.filter(
            (item) =>
              ![
                secondDimensionRowSelected?.key,
                thirdDimensionRowSelected?.key,
                fourthDimensionRowSelected?.key,
              ].includes(item),
          );
          setThirdDimensionRowSelected(selectedOption);
          setFourthDimensionRowSelected(selectedOption);
          setUtilizedDimensions(tempUtilizedDimensions);
        } else {
          // existing key should be freed up,
          tempUtilizedDimensions = tempUtilizedDimensions.filter(
            (item) => item !== secondDimensionRowSelected?.key,
          );
          setUtilizedDimensions(tempUtilizedDimensions);
        }
        break;
      }
      case 'third': {
        setThirdDimensionRowSelected(selectedOption);
        if (selectedOption.key === 'additional_none') {
          tempUtilizedDimensions = tempUtilizedDimensions.filter(
            (item) =>
              ![thirdDimensionRowSelected?.key, fourthDimensionRowSelected?.key].includes(item),
          );
          setFourthDimensionRowSelected(selectedOption);
          setUtilizedDimensions(tempUtilizedDimensions);
        } else {
          // existing key should be freed up,
          tempUtilizedDimensions = tempUtilizedDimensions.filter(
            (item) => item !== thirdDimensionRowSelected?.key,
          );
          setUtilizedDimensions(tempUtilizedDimensions);
        }
        break;
      }
      case 'fourth': {
        setFourthDimensionRowSelected(selectedOption);
        if (selectedOption.key === 'additional_none') {
          tempUtilizedDimensions = tempUtilizedDimensions.filter(
            (item) => ![fourthDimensionRowSelected?.key].includes(item),
          );
          setUtilizedDimensions(tempUtilizedDimensions);
        } else {
          // existing key should be freed up,
          tempUtilizedDimensions = tempUtilizedDimensions.filter(
            (item) => item !== fourthDimensionRowSelected?.key,
          );
          setUtilizedDimensions(tempUtilizedDimensions);
        }
        break;
      }
    }

    // update all the drop downs
    setPrimaryOptions([
      ...filterChangeHandler(
        primarySelectionData,
        tempUtilizedDimensions.filter((option) =>
          dimension === 'primary'
            ? option !== selectedOption.key
            : option !== primaryRowSelected?.key,
        ),
      ),
    ]);

    setSecondaryOptions(
      filterChangeHandler(
        secondarySelectionData,
        tempUtilizedDimensions.filter((option) =>
          dimension === 'secondary'
            ? option !== selectedOption.key
            : option !== secondaryRowSelected?.key,
        ),
      ),
    );

    setMeasureOptions(
      filterChangeHandler(
        measureSelectionData,
        tempUtilizedDimensions.filter((option) =>
          dimension === 'measure'
            ? option !== selectedOption.key
            : option !== measureRowSelected?.key,
        ),
      ),
    );

    setSecondDimensionOptions(
      filterChangeHandler(
        additionalDimensionSelectionData,
        tempUtilizedDimensions.filter((option) =>
          dimension === 'second'
            ? option !== selectedOption.key
            : option !== secondDimensionRowSelected?.key,
        ),
      ),
    );

    setThirdDimensionOptions(
      filterChangeHandler(
        additionalDimensionSelectionData,
        tempUtilizedDimensions.filter((option) =>
          dimension === 'third'
            ? option !== selectedOption.key
            : option !== thirdDimensionRowSelected?.key,
        ),
      ),
    );

    setFourthDimensionOptions(
      filterChangeHandler(
        additionalDimensionSelectionData,
        tempUtilizedDimensions.filter((option) =>
          dimension === 'fourth'
            ? option !== selectedOption.key
            : option !== fourthDimensionRowSelected?.key,
        ),
      ),
    );

    changePagination({
      ...resetSkip,
    });
  };

  const VIEW_ROLE = useMemo(
    () =>
      getViewAs(currentRole, location.pathname, {
        reportId,
        reportType,
      }),
    [currentRole, location.pathname, reportId, reportType],
  );

  const optionChangeHandler = (type: EOptions, val?: TDropDownValues) => {
    // Dimensions -->
    if (type === EOptions.primaryDimension) {
      if (val === null) {
        setPrimaryRowSelected(null);
        setSecondaryRowSelected(null);
        setMeasureRowSelected(null);
        setPrimaryOptions([...PRIMARY_OPTIONS]);
        setSecondaryOptions([...SECONDARY_OPTIONS]);
        setMeasureOptions([...MEASURE_OPTIONS]);
        return;
      }
      onDimensionOptionChange(val as string, 'primary');
    } else if (type === EOptions.secondaryDimension) {
      onDimensionOptionChange(val as string, 'secondary');
    } else if (type === EOptions.secondDimension) {
      onDimensionOptionChange(val as string, 'second');
    } else if (type === EOptions.thirdDimension) {
      onDimensionOptionChange(val as string, 'third');
    } else if (type === EOptions.fourthDimension) {
      onDimensionOptionChange(val as string, 'fourth');
    } else if (type === EOptions.measure) {
      onDimensionOptionChange(val as string, 'measure');
      // Filters -->
    } else if (type === EOptions.timeFrame) {
      setMonthSelected(val as string);
    } else if (type === EOptions.themes) {
      setThemesOptionSelected(val as TOption[]);
    } else if (type === EOptions.teams) {
      setTeamsOptionSelected(val as TOption[]);
    } else if (type === EOptions.surveys) {
      setSurveysOptionSelected(val as TOption[]);
    } else if (type === EOptions.reviews) {
      setReviewsOptionSelected(val as TOption[]);
    } else if (type === EOptions.jobs) {
      setJobsOptionSelected(val as TOption[]);
    } else if (type === EOptions.jobGroups) {
      setJobGroupsSelected(val as TOption[]);
    } else if (type === EOptions.genders) {
      setGendersSelected(val as TOption[]);
    } else if (type === EOptions.ageGroups) {
      setAgeGroupSelected(val as TOption[]);
    } else if (type === EOptions.educationLevels) {
      setEducationLevelsSelected(val as TOption[]);
    } else if (type === EOptions.includeCompanyAverage) {
      setCompanyAverageSelected((average) => !average);
    } else if (type === EOptions.includeBenchmark) {
      setIndustryBenchMarkSelected((benchmark) => !benchmark);
    } else if (type === EOptions.isHeatmapColored) {
      setIsHeatmapSelected((heatmap) => !heatmap);
    } else if (type === EOptions.includeTeamAverage) {
      setTeamAverageSelected((average) => !average);
    } else if (type === EOptions.includePeerReviewAverage) {
      setPeerReviewAverageSelected((peerAverage) => !peerAverage);
    } else if (type === EOptions.includeSelfReviewAverage) {
      setSelfReviewAverageSelected((selfAverage) => !selfAverage);
    } else if (type === EOptions.skills) {
      setSkillOptionsSelected(val as TOption[]);
    } else if (type === EOptions.skillCategories) {
      setSkillCategoryOptionsSelected(val as TOption[]);
    } else if (type === EOptions.members) {
      setMemberOptionsSelected(val as TOption[]);
    } else if (type === EOptions.performanceCategories) {
      setPerformanceCategoryOptionsSelected(val as TOption[]);
    } else if (type === EOptions.sorting) {
      const sortedArray = (val as string).split('__') || [];
      setSorting({
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      });
    } else if (type === EOptions.reset) {
      setTeamsOptionSelected([]);
      setThemesOptionSelected([]);
      setSurveysOptionSelected([]);
      setReviewsOptionSelected([]);

      setAgeGroupSelected([]);
      setGendersSelected([]);
      setJobsOptionSelected([]);
      setJobGroupsSelected([]);
      setEducationLevelsSelected([]);
      setSkillOptionsSelected([]);
      setSkillCategoryOptionsSelected([]);
      setPerformanceCategoryOptionsSelected([]);
      setMemberOptionsSelected([]);
    }
  };

  const getSelectedFilters = (filterOptionArray: TOption[], selectedFilterData?: string[]) => {
    return filterOptionArray.filter((item) => selectedFilterData?.includes(item.key));
  };

  const initData = async () => {
    try {
      setIsLoading(true);
      let tempThemesOptions = [] as TOption[];
      let tempTeamsOptions = [] as TOption[];
      let tempSurveysOptions = [] as TOption[];
      let tempReviewsOptions = [] as TOption[];
      let newJobOptions = [] as TOption[];
      let newJobGroupOptions = [] as TOption[];
      let newGenderOptions = [] as TOption[];
      let newAgeGroupOptions = [] as TOption[];
      let newEducationLevelOptions = [] as TOption[];
      let newSkillOptions = [] as TOption[];
      let newSkillCategoryOptions = [] as TOption[];
      let newPerformanceCategoryOptions = [] as TOption[];
      let newMemberOptions = [] as TOption[];
      let primaryOptions = [] as TOption[];
      let secondaryOptions = [] as TOption[];
      let additionalDimensionOptions = [] as TOption[];
      let measureOptions = [] as TOption[];
      let visualTypes = {} as { [key: string]: TVisualType };

      // setting additional dimensions to their defaults on options fetch
      setSecondDimensionRowSelected(null);
      setThirdDimensionRowSelected(null);
      setFourthDimensionRowSelected(null);
      setUtilizedDimensions([]);

      const tabApiData = await getTabDetails(
        params.reportId || 'default',
        params.reportType || REPORT_TYPES.ENGAGEMENT,
        VIEW_ROLE,
      );

      visualTypes = tabApiData?.data?.visualTypes;

      const getEmptyOption = (key: string) => {
        return { key, title: () => '-' };
      };

      primaryOptions = tabApiData?.data?.primaryDimension?.options
        ? PRIMARY_OPTIONS.filter((option) =>
            tabApiData?.data?.primaryDimension?.options?.includes(option.key),
          )
        : PRIMARY_OPTIONS;

      secondaryOptions = tabApiData?.data?.secondaryDimension?.options
        ? SECONDARY_OPTIONS.filter((option) =>
            tabApiData?.data?.secondaryDimension?.options?.includes(option.key),
          )
        : SECONDARY_OPTIONS;

      additionalDimensionOptions = tabApiData?.data?.additionalDimensions?.options
        ? [
            getEmptyOption('additional_none'),
            ...ADDITIONAL_OPTIONS.filter((option) =>
              tabApiData?.data?.additionalDimensions?.options?.includes(option.key),
            ),
          ]
        : ADDITIONAL_OPTIONS;

      measureOptions = tabApiData?.data?.measure?.options
        ? MEASURE_OPTIONS.filter((option) =>
            tabApiData?.data?.measure?.options?.includes(option.key),
          )
        : MEASURE_OPTIONS;

      const facetsToFetch = [];

      if (tabApiData.data.filters.surveys?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.SURVEY);
      }
      if (tabApiData.data.filters.themes?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME);
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS._LEARNED_THEME);
      }
      if (tabApiData.data.filters.teams?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM);
      }
      if (tabApiData.data.filters.ageGroups?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.AGE_GROUP);
      }
      if (tabApiData.data.filters.genders?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.GENDER);
      }
      if (tabApiData.data.filters.educationLevels?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.EDUCATION_LEVEL);
      }
      if (tabApiData.data.filters.jobs?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.JOB);
      }
      if (tabApiData.data.filters.jobGroups?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.JOB_GROUP);
      }
      if (tabApiData.data.filters.reviews?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.REVIEW);
      }
      if (tabApiData.data.filters.skills?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.SKILL);
      }
      if (tabApiData.data.filters.skillCategories?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.SKILL_CATEGORY);
      }
      if (tabApiData.data.filters.members?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.MEMBER);
      }
      if (tabApiData.data.filters.performanceCategories?.isEnabled) {
        facetsToFetch.push(ENGAGEMENT_REPORT_CHART_DIMENSIONS.PERFORMANCE_CATEGORY);
      }

      const facets = await getFacets(
        params.reportType || REPORT_TYPES.ENGAGEMENT,
        facetsToFetch,
        VIEW_ROLE,
      );

      tempTeamsOptions = handleOptionInit(facets?.data?.teams || []);
      tempThemesOptions = handleOptionInit(facets?.data?.themes || []);
      tempSurveysOptions = handleOptionInit(facets?.data?.surveys || []);
      tempReviewsOptions = handleOptionInit(facets?.data?.reviews || []);
      newJobOptions = handleOptionInit(facets?.data?.jobs || []);
      newJobGroupOptions = handleOptionInit(facets?.data?.jobGroups || []);
      newGenderOptions = handleOptionInit(facets?.data?.genders || []);
      newAgeGroupOptions = handleOptionInit(facets?.data?.ageGroups || []);
      newEducationLevelOptions = handleOptionInit(facets?.data?.educationLevels || []);
      newSkillOptions = handleOptionInit(facets?.data?.skills || []);
      newSkillCategoryOptions = handleOptionInit(facets?.data?.skillCategories || []);
      newPerformanceCategoryOptions = handleOptionInit(facets?.data?.performanceCategories || []);
      newMemberOptions = handleOptionInit(facets?.data?.members || []);
      setLearnedThemesOptions(handleOptionInit(facets?.data?.learnedThemes || []));

      const selectedTabData = tabApiData.data;
      const selectedFilterData = selectedTabData?.filters || {};
      const selectedOptData = selectedTabData?.options || {};
      const enabledFilterArr = Object.entries(selectedFilterData).filter(
        ([_, value]) => value.isEnabled,
      );
      const enabledOptionArr = Object.entries(selectedOptData).filter(
        ([_, value]) => value?.isEnabled,
      );
      const enabledFilterKeys = enabledFilterArr.map(([key]) => key as EOptions);
      const enabledOptionKeys = enabledOptionArr.map(([key]) => key as EOptions);

      const initiatePrimary = PRIMARY_OPTIONS.filter((option) => option.key === 'theme');
      const initiateMeasure = MEASURE_OPTIONS.filter((option) => option.key === 'month');

      const tempPrimaryRowSelected =
        PRIMARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.primaryDimension?.value,
        )[0] ||
        initiatePrimary[0] ||
        null;

      const tempSecondaryRowSelected =
        SECONDARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.secondaryDimension?.value,
        )[0] || null;

      const tempMeasureRowSelected =
        MEASURE_OPTIONS.filter((option) => option.key === selectedTabData?.measure?.value)[0] ||
        initiateMeasure[0] ||
        null;

      const tempCompanyAverageSelected = selectedOptData?.includeCompanyAverage?.value || false;
      const tempIndustryBenchMarkSelected = selectedOptData?.includeBenchmark?.value || false;
      const tempIsHeatmapSelected = selectedOptData?.isHeatmapColored?.value || false;
      const tempTeamAverageSelected = selectedOptData?.includeTeamAverage?.value || false;
      const tempIncludePeerReviewAverageSelected =
        selectedOptData?.includePeerReviewAverage?.value || false;
      const tempIncludeSelfReviewAverageSelected =
        selectedOptData?.includeSelfReviewAverage?.value || false;

      const timeFrameEnabled = enabledFilterArr.filter(([key]) => key === 'timeFrame');
      const tempMonthSelected =
        timeFrameEnabled.length > 0 ? `${timeFrameEnabled[0][1].value}` : MONTH_OPTIONS[0].key;

      const tempThemesOptionSelected =
        tempThemesOptions.filter((item) => selectedFilterData?.themes?.value.includes(item.key)) ||
        [];

      const tempTeamsOptionSelected =
        tempTeamsOptions.filter((item) => {
          if (team) {
            return item.key === team;
          }
          return selectedFilterData?.teams?.value.includes(item.key);
        }) || [];

      const tempSurveysOptionSelected =
        tempSurveysOptions.filter((item) => {
          if (survey) {
            return item.key === survey;
          } else {
            return selectedFilterData?.surveys?.value.includes(item.key);
          }
        }) || [];

      const tempMembersOptionSelected =
        newMemberOptions.filter((item) => {
          if (member) {
            return item.key === member;
          }
          return selectedFilterData?.members?.value.includes(item.key);
        }) || [];

      const tempReviewsOptionSelected =
        tempReviewsOptions.filter((item) => {
          if (review) {
            return item.key === review;
          }
          return selectedFilterData?.reviews?.value.includes(item.key);
        }) || [];

      setCurrentTab(selectedTabData?.viewType || null);
      setTabConfiguration(selectedTabData);

      // Set Options of Dimensions and Filters
      setEnabledOptions([
        ...enabledFilterKeys,
        ...enabledOptionKeys,
        ...(selectedTabData?.primaryDimension?.isEnabled ? [EOptions.primaryDimension] : []),
        ...(selectedTabData?.secondaryDimension?.isEnabled ? [EOptions.secondaryDimension] : []),
        ...(selectedTabData?.additionalDimensions?.isEnabled
          ? [EOptions.additionalDimensions]
          : []),
        ...(selectedTabData?.measure?.isEnabled ? [EOptions.measure] : []),
        ...(selectedOptData?.exportOptions?.isEnabled ? [EOptions.exportOptions] : []),
      ]);

      // Set Initial values for Dimensions and Filters
      setPrimaryRowSelected(tempPrimaryRowSelected);
      setPrimarySelectionData(primaryOptions);
      setSecondaryRowSelected(tempSecondaryRowSelected);
      setSecondarySelectionData(secondaryOptions);
      setMeasureRowSelected(tempMeasureRowSelected);
      setAdditionalDimensionSelectionData(additionalDimensionOptions);
      setMeasureSelectionData(measureOptions);
      setPrimaryOptions(filterChangeHandler(primaryOptions, [tempMeasureRowSelected?.key]));
      setMeasureOptions(filterChangeHandler(measureOptions, [tempPrimaryRowSelected?.key]));
      setSecondaryOptions(
        filterChangeHandler(secondaryOptions, [
          tempPrimaryRowSelected?.key,
          tempMeasureRowSelected?.key,
        ]),
      );

      if (tabApiData?.data?.additionalDimensions?.isEnabled) {
        const additionalDimensionValues = tabApiData?.data?.additionalDimensions
          ?.value as unknown as string[];

        const additionalOptionSetters = [
          setSecondDimensionOptions,
          setThirdDimensionOptions,
          setFourthDimensionOptions,
        ];

        additionalOptionSetters.map((setter, i) => {
          setter(
            filterChangeHandler(additionalDimensionOptions, [
              tempPrimaryRowSelected?.key,
              tempSecondaryRowSelected?.key,
              tempMeasureRowSelected?.key,
              ...removeIndex(additionalDimensionValues, i),
            ]),
          );
        });

        const additionalDimensionSetters = [
          setSecondDimensionRowSelected,
          setThirdDimensionRowSelected,
          setFourthDimensionRowSelected,
        ];
        additionalDimensionValues.map((value, i) => {
          // assume backend options request will not send same dimension value more than once
          const dimensionFound = additionalDimensionOptions.find((option) => option.key === value);
          if (dimensionFound) {
            additionalDimensionSetters[i](dimensionFound);
            setUtilizedDimensions(
              getNewlyUtilizedDimensions([dimensionFound.key], utilizedDimensions),
            );
          }
        });
      }

      setCompanyAverageSelected(tempCompanyAverageSelected);
      setTeamAverageSelected(tempTeamAverageSelected);
      setPeerReviewAverageSelected(tempIncludePeerReviewAverageSelected);
      setSelfReviewAverageSelected(tempIncludeSelfReviewAverageSelected);
      setIndustryBenchMarkSelected(tempIndustryBenchMarkSelected);
      setIsHeatmapSelected(tempIsHeatmapSelected);

      setJobGroupOptions(newJobGroupOptions);
      setJobGroupsSelected(
        getSelectedFilters(newJobGroupOptions, selectedFilterData?.jobGroups?.value || []),
      );

      setJobOptions(newJobOptions);
      setJobsOptionSelected(
        getSelectedFilters(newJobOptions, selectedFilterData?.jobs?.value || []),
      );

      setGendersSelected(
        getSelectedFilters(newGenderOptions, selectedFilterData?.genders?.value || []),
      );
      setGenderOptions(newGenderOptions);

      setAgeGroupOptions(newAgeGroupOptions);
      setAgeGroupSelected(
        getSelectedFilters(newAgeGroupOptions, selectedFilterData?.ageGroups?.value || []),
      );

      setEducationLevelOptions(newEducationLevelOptions);
      setEducationLevelsSelected(
        getSelectedFilters(
          newEducationLevelOptions,
          selectedFilterData?.educationLevels?.value || [],
        ),
      );

      setSkillOptions(newSkillOptions);
      setSkillOptionsSelected(
        getSelectedFilters(newSkillOptions, selectedFilterData?.skills?.value || []),
      );

      setSkillCategoryOptions(newSkillCategoryOptions);
      setSkillCategoryOptionsSelected(
        getSelectedFilters(
          newSkillCategoryOptions,
          selectedFilterData?.skillCategories?.value || [],
        ),
      );

      setMemberOptions(newMemberOptions);
      setMemberOptionsSelected(tempMembersOptionSelected);

      setPerformanceCategoryOptions(newPerformanceCategoryOptions);
      setPerformanceCategoryOptionsSelected(
        getSelectedFilters(
          newPerformanceCategoryOptions,
          selectedFilterData?.performanceCategories?.value || [],
        ),
      );

      setVisualTypes(visualTypes);
      setThemesOptions(tempThemesOptions);
      setSurveysOptions(tempSurveysOptions);
      setReviewsOptions(tempReviewsOptions);
      setTeamsOptions(tempTeamsOptions);
      setMonthSelected(tempMonthSelected);
      setThemesOptionSelected(tempThemesOptionSelected);
      setTeamsOptionSelected(tempTeamsOptionSelected);
      setSurveysOptionSelected(tempSurveysOptionSelected);
      setReviewsOptionSelected(tempReviewsOptionSelected);
      setExportOptionArr((selectedOptData?.exportOptions?.value || []) as EExportOptions[]);
      setSorting(tabApiData.data.sorting || {});
      setIsLoading(false);
      setUtilizedDimensions(
        getNewlyUtilizedDimensions(
          [tempPrimaryRowSelected.key, tempSecondaryRowSelected?.key, tempMeasureRowSelected?.key],
          utilizedDimensions,
        ),
      );
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      initData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, team, survey, member, review]);

  const shouldShowLuca =
    primaryRowSelected?.key === ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME &&
    [ROLES.ADMIN, ROLES.COACH].includes(VIEW_ROLE);

  const shouldBenchmarkDisabled =
    industryBenchMarkSelected &&
    dimensionSelected?.primary?.key === ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME &&
    (dimensionSelected?.secondary?.key === 'secondary_none' ||
      dimensionSelected?.secondary?.key === 'primary_none' ||
      !dimensionSelected?.secondary?.key) &&
    [
      ENGAGEMENT_REPORT_CHART_DIMENSIONS.MONTH,
      ENGAGEMENT_REPORT_CHART_DIMENSIONS.QUARTER,
      ENGAGEMENT_REPORT_CHART_DIMENSIONS.YEAR,
    ].includes(dimensionSelected?.measure?.key as ENGAGEMENT_REPORT_CHART_DIMENSIONS);

  const getTabBody = () => {
    switch (currentTab) {
      case REPORT_CHART_TYPES.BY_MONTH:
        return <Overtime optionChangeHandler={optionChangeHandler} />;
      case REPORT_CHART_TYPES.BY_TWO_DIMENSION:
        return <Custom optionChangeHandler={optionChangeHandler} />;
      case REPORT_CHART_TYPES.BY_ONE_DIMENSION:
        return <Total optionChangeHandler={optionChangeHandler} />;
      default:
        return (
          <LoaderCtr>
            <Loader />
          </LoaderCtr>
        );
    }
  };

  return (
    <EngagementReportContext.Provider
      value={{
        themesOptions,
        skillOptions,
        primaryRowSelected,
        viewAs: VIEW_ROLE,
        tabConfiguration,
        learnedThemesOptions,
        reportType: currentReportType,
        exportOptionArr,
        enabledOptions,
        shouldShowLuca,
        shouldBenchmarkDisabled,
        dimensions: {
          primary: primaryRowSelected?.key || '',
          secondary: secondaryRowSelected?.key || '',
          measure: measureRowSelected?.key || '',
          secondDimension: secondDimensionRowSelected?.key || '',
          thirdDimension: thirdDimensionRowSelected?.key || '',
          fourthDimension: fourthDimensionRowSelected?.key || '',
        },
        filters: {
          monthSelected,
          themesOptionSelected,
          teamsOptionSelected,
          surveysOptionSelected,
          jobsSelected,
          jobsGroupsSelected,
          gendersSelected,
          ageGroupSelected,
          educationLevelsSelected,
          reviewsOptionSelected,
          skillOptionsSelected,
          skillCategoryOptionsSelected,
          performanceCategoryOptionsSelected,
          memberOptionsSelected,
        },
        sorting,
        options: {
          includeCompanyAverage: companyAverageSelected,
          includeBenchmark: industryBenchMarkSelected,
          includeTeamAverage: teamAverageSelected,
          includePeerReviewAverage: peerReviewAverageSelected,
          includeSelfReviewAverage: selfReviewAverageSelected,
          isHeatmapColored: isHeatmapSelected,
        },
        isWaiting: isLoading,
        showMenu,
        visualTypes,
        viewType: currentTab,
        reportId: reportId || null,
      }}
    >
      <BaseLayout maxWidth={'1270px'} noMarginTop>
        {getTabBody()}
        <ControlPanel
          optionMenu={optionMenu}
          isLoading={isLoading}
          showMenu={showMenu}
          onShow={(val) => setShowMenu(val)}
          optionChangeHandler={optionChangeHandler}
          monthSelected={monthSelected}
          enabledOptions={enabledOptions}
          currentTab={currentTab}
        />
      </BaseLayout>
    </EngagementReportContext.Provider>
  );
};

export default withI18n()(EngagementReportPage);
