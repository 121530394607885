import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div<{ isActive?: boolean }>`
  width: 100%;
  padding: 16px 25px 16px 22px;
  border: 1px solid ${COLORS.BORDER_HARD};
  border-radius: 10px;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${COLORS.HOVER};
          box-shadow: 0 4px 4px 0 rgba(30, 8, 99, 0.21);
        `
      : css`
          background-color: ${COLORS.WHITE};
        `};
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  font-size: 12px;
  color: ${COLORS.DARK_GRAY};
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const Title = styled.div<{ withBottom?: boolean }>`
  font-size: 14px;
  color: ${COLORS.BLACK};
  margin-bottom: ${({ withBottom }) => (withBottom ? '26px' : '0')};
  cursor: pointer;
`;

const Main = styled.div`
  display: flex;
  gap: 40px;
  color: ${COLORS.BLACK};

  & .index {
    font-size: 14px;
  }
`;

const Separator = styled.span`
  padding-left: 15px;
  padding-right: 15px;
`;

const FooterIconContainer = styled.div`
  margin-right: 4px;
`;

export { Wrapper, Header, Main, Title, Separator, Footer, FooterIconContainer };
