import React, { useState } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_STATUS } from '@learned/constants';
import {
  IReviewQuestion,
  IReviewRating,
  IReviewTheme,
  ITask,
  IUserReview,
  IUserReviewQuestion,
  IUserReviewQuestionRating,
  IUserReviewQuestionSkillCategoryWithJobProfile,
  IUserReviewQuestionText,
} from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import { PlanGoalQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/PlanGoalQuestion';
import { RatingQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/RatingQuestion';
import { SkillQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion';
import { TextAreaQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/TextAreaQuestion';
import { IPartialReviewRating } from '~/pages/ReviewGiveFeedback/types';

import {
  CloseButton,
  Content,
  Footer,
  Header,
  QuestionDescription,
  QuestionTitle,
  QuestionWrapper,
  Title,
} from './design';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { IUserReviewQuestionGrouped } from '../../hooks/useReviewRatings';
import { AnswerType, IAnswer } from '../../types';
import { IUserReviewQuestionSkillCategoryGrouped } from '../../utils';

interface IQuestionEditModalProps {
  userReview?: IUserReview & {
    tasks: ITask[];
    employeeTasksInOtherUserReviews: ITask[];
    userReviewQuestions: IUserReviewQuestion[];
  };
  rating: {
    reviewRating?: AnswerType;
    question: IUserReviewQuestionGrouped;
    questionIndex: number;
  };
  updateReviewRating: (answer: IAnswer[], status?: REVIEW_RATING_STATUS) => void;
  onClose: () => void;
}

export const QuestionEditModal = ({
  userReview,
  onClose,
  rating,
  updateReviewRating,
}: IQuestionEditModalProps) => {
  const { i18n } = useLingui();
  const [answers, setAnswers] = useState<IAnswer[]>([
    {
      questionId: (rating.question as IReviewQuestion).id,
      answer: rating.reviewRating?.answer,
      comment: rating.reviewRating?.comment,
      isNotApplicable: rating.reviewRating?.answer === null,
    },
  ]);
  const languageState = useLanguageState();
  const getMultiLangString = useMultiLangString();

  const { reviewRating, question, questionIndex } = rating;

  const questionTitle = getMultiLangString(question.name);
  const questionDescription = getMultiLangString(question.description || '');
  const themeName = getMultiLangString((question.theme as IReviewTheme)?.name);

  const getQuestionData = (question: IUserReviewQuestionGrouped) => {
    switch (question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
      case REVIEW_QUESTION_TYPES.RATING:
        return {
          yourRating: reviewRating as IPartialReviewRating,
          questionNumber: questionIndex.toString(),
          questionTitle,
          themeName,
          canAnswer: true,
          type: question.type as REVIEW_QUESTION_TYPES.TEXT | REVIEW_QUESTION_TYPES.RATING,
          question: question as unknown as IUserReviewQuestionText,
        };
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL: {
        const questionsList = (
          question as unknown as IUserReviewQuestionSkillCategoryGrouped['skills'][0]
        ).questions.map(
          (
            question: IUserReviewQuestionSkillCategoryWithJobProfile & {
              reviewRatings?: IReviewRating[];
            },
            index: number,
          ) => {
            return {
              question,
              // @ts-ignore
              yourRating: question.reviewRating?.[index],
              questionNumber: questionIndex.toString(),
              questionTitle,
              themeName,
              canAnswer: true,
              type: question.type as
                | REVIEW_QUESTION_TYPES.SKILL_CATEGORY
                | REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
            };
          },
        );
        return questionsList;
      }
    }
  };

  const onChange = (data: {
    questionId: string;
    answer?: string | number | null;
    comment?: string;
    isNotApplicable?: boolean;
  }) => {
    setAnswers([
      ...answers
        .filter((rating) => rating.questionId !== data.questionId)
        .filter((rating) => rating.questionId),
      data,
    ]);
  };

  const getSkillQuestionAnswers = (reviewRatings: IReviewRating[]) => {
    return reviewRatings
      .map((reviewRating) => {
        return {
          value: (reviewRating?.answer as number | null) ?? -1,
        };
      })
      .flat();
  };

  const onSave = () => {
    updateReviewRating(answers, rating?.reviewRating?.status || REVIEW_RATING_STATUS.PUBLISHED);
  };

  const getQuestion = (question: IUserReviewQuestionGrouped) => {
    const comment = reviewRating?.comment ? reviewRating.comment : undefined;
    switch (question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
        return (
          <TextAreaQuestion
            languageState={languageState}
            questionData={rating.question as unknown as IUserReviewQuestionText}
            onChange={(data: {
              questionId: string;
              answer?: string;
              comment?: string;
              isNotApplicable?: boolean;
            }) => onChange(data)}
            // @ts-ignore
            question={getQuestionData(question)}
            canAnswer
            defaultValues={{
              answer: (reviewRating?.answer as string | null) ?? '',
              isNotApplicable: false,
            }}
          />
        );
      case REVIEW_QUESTION_TYPES.RATING:
        return (
          <RatingQuestion
            languageState={languageState}
            questionData={rating.question as unknown as IUserReviewQuestionRating}
            onChange={(data: {
              questionId: string;
              answer?: number;
              comment?: string;
              isNotApplicable?: boolean;
            }) => onChange(data)}
            // @ts-ignore
            question={getQuestionData(question)}
            canAnswer
            defaultValues={{
              answer: (reviewRating?.answer as string | null) ?? '',
              isNotApplicable: false,
              comment,
            }}
            useMultiLangString={useMultiLangString}
          />
        );
      case REVIEW_QUESTION_TYPES.GOAL_PLAN:
        return <PlanGoalQuestion userFrom={userReview?.tasks?.[0].userFrom} />;
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
        return (
          <SkillQuestion
            languageState={languageState}
            onChange={(data: {
              questionId: string;
              answer?: number;
              comment?: string;
              isNotApplicable?: boolean;
            }) => onChange(data)}
            // @ts-ignore
            focusAreaQuestions={getQuestionData(question)}
            canAnswer
            defaultValues={{
              // @ts-ignore
              answers: getSkillQuestionAnswers(question.reviewRating),
              isNotApplicable: false,
              comment,
            }}
            useMultiLangString={useMultiLangString}
          />
        );
    }
  };

  return (
    <Modal
      width={750}
      contentStyles={{ padding: '20px 32px 25px' }}
      hideFooter
      isHideHeader
      centerModal
      onClose={onClose}
      showDivider={false}
    >
      <Header>
        <Title>
          <Trans>{`Question ${questionIndex} | ${themeName}`}</Trans>
        </Title>
        <CloseButton
          size={ButtonSize.MEDIUM}
          onClick={onClose}
          icon={ICONS.CLOSE}
          variant={ButtonVariant.ICON}
        />
      </Header>
      <Content>
        <QuestionTitle>{questionTitle}</QuestionTitle>
        {questionDescription && <QuestionDescription>{questionDescription}</QuestionDescription>}
        <QuestionWrapper>{getQuestion(question)}</QuestionWrapper>
      </Content>
      <Footer>
        <Button
          size={ButtonSize.MEDIUM}
          onClick={onClose}
          variant={ButtonVariant.SECONDARY}
          label={i18n._(t`Cancel`)}
        />
        <Button
          size={ButtonSize.MEDIUM}
          onClick={onSave}
          variant={ButtonVariant.PRIMARY}
          label={i18n._(t`Save`)}
        />
      </Footer>
    </Modal>
  );
};
