import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, useForm } from 'react-hook-form';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { CommentSection, NAOption } from './Components';
import { FocusAreaModal } from './Components/FocusAreaModal';
import { MockData as focusAreaOptions } from './Components/FocusAreaModal/mockData';
import { FocusAreaQuestionPlaceholder } from './Components/FocusAreaQuestionPlaceholder';
import { FocusAreaSection } from './Components/FocusAreaSection';
import { RatingAnswers } from './Components/RatingAnswers';
import { RatingLabelPlaceholder } from './Components/RatingLabelPlaceholder';
import { SkillQuestionRatingLabel } from './Components/SkillQuestionRatingLabel';
import {
  AllAnswers,
  Answers,
  DefinitionContainer,
  JobIconWrapper,
  JobNameContainer,
  OldJobSkillQuestionLabel,
  OldSkillQuestionContentWrapper,
  QuestionHeader,
  QuestionWrapper,
  RatingLabelContainer,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { IQuestionDefaultData, TSelectedLevel } from '../../types';
import { WithComments } from '../QuestionView';

import type { IUserReviewQuestionRating } from '@learned/types';

interface IQuestionViewOldSkillQuestionProps {
  questionData: IUserReviewQuestionRating;
  languageState: ILanguageStateReturn;
  question: IQuestionDefaultData;
  onChange?: (data: {
    questionId: string;
    answer?: number;
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  defaultValues: IQuestionRatingForm;
  canAnswer: boolean;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export interface IQuestionRatingForm extends WithComments {
  answer: string | null;
  isNotApplicable: boolean;
}

export const OldSkillQuestion = ({
  question,
  questionData,
  defaultValues,
  onChange,
  canAnswer,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
}: IQuestionViewOldSkillQuestionProps) => {
  const { i18n } = useLingui();
  const formMethods = useForm<IQuestionRatingForm>({ defaultValues });
  const { control, watch, setValue } = formMethods;
  const notApplicableWatch = watch('isNotApplicable');

  const [selectedLevel, setSelectedLevel] = useState<TSelectedLevel | null>(null);
  const $openFocusAreaModal = useBoolState(false);
  const $isNotApplicable = useBoolState(notApplicableWatch);
  const displayAnswers = !!(
    question.otherCoachRatings?.length ||
    question.otherPeerRatings?.length ||
    question.otherSelfRating
  );

  useEffect(() => {
    $isNotApplicable.set(notApplicableWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notApplicableWatch]);

  const options = questionData?.settings?.options ?? [];

  // Following mock data will be deleted once the api integration is completed
  const focusAreas = [
    {
      label: {
        en_GB:
          'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        nl_NL:
          'Makes a distinction between functional inforamtion and interpretations or assumptions.',
      },
    },
    {
      label: {
        en_GB: 'Calculates long-term consequences.',
        nl_NL: 'Calculates long-term consequences.',
      },
    },
  ];

  const openFocusAreaModal = (selectedLevel: TSelectedLevel) => {
    $openFocusAreaModal.on();
    setSelectedLevel(selectedLevel);
  };

  return (
    <QuestionWrapper>
      {canAnswer && (
        <>
          <OldSkillQuestionContentWrapper className={$isNotApplicable.value ? 'disabled' : ''}>
            <QuestionHeader>
              <OldJobSkillQuestionLabel>
                <Trans>Select your answer</Trans>
              </OldJobSkillQuestionLabel>
              {!questionData?.settings?.isAnswerObligated && (
                <Controller
                  name="isNotApplicable"
                  control={control}
                  render={({ field }) => (
                    <NAOption
                      isChecked={field.value}
                      onChange={(value: boolean) => {
                        field.onChange(value);
                        onChange?.({ questionId: questionData.id, isNotApplicable: value });
                      }}
                    />
                  )}
                />
              )}
            </QuestionHeader>
            <JobNameContainer>
              <JobIconWrapper>
                <Icon icon={ICONS.CAREER} size={ICON_SIZES.SMALL} />
              </JobIconWrapper>
              <span>[Job 1], [Job 2]</span>
            </JobNameContainer>
            <RatingLabelContainer>
              <Controller
                name="answer"
                control={control}
                render={({ field }) => (
                  <>
                    {options.length <= 5 ? (
                      options.map((option, index) => {
                        index += 1;
                        const isSelected = !!(field.value && Number(field.value) === index);
                        return (
                          <SkillQuestionRatingLabel
                            option={option}
                            key={index}
                            // This is a hard coded value which will change on API integration
                            isExpected={index === 3}
                            isSelected={isSelected}
                            useMultiLangString={useMultiLangString}
                            onChange={() => {
                              setValue('answer', !isSelected ? index.toString() : null);
                              onChange?.({
                                questionId: questionData.id,
                                answer: !isSelected ? index : -1,
                              });
                            }}
                          />
                        );
                      })
                    ) : options.length > 5 ? (
                      <>
                        {options.map((_, index) => {
                          index += 1;
                          const isSelected = !!(field.value && Number(field.value) === index);
                          if (index === 1) {
                            return (
                              <SkillQuestionRatingLabel
                                key={index}
                                option={options[0]}
                                isSelected={isSelected}
                                useMultiLangString={useMultiLangString}
                                onChange={() => {
                                  setValue('answer', !isSelected ? '1' : null);
                                  onChange?.({
                                    questionId: questionData.id,
                                    answer: !isSelected ? index : -1,
                                  });
                                }}
                              />
                            );
                          } else if (index === options.length) {
                            return (
                              <SkillQuestionRatingLabel
                                key={index}
                                option={options[options.length - 1]}
                                isSelected={isSelected}
                                useMultiLangString={useMultiLangString}
                                onChange={() => {
                                  setValue(
                                    'answer',
                                    !isSelected ? (options.length - 1).toString() : null,
                                  );
                                  onChange?.({
                                    questionId: questionData.id,
                                    answer: !isSelected ? index : -1,
                                  });
                                }}
                              />
                            );
                          }
                          return (
                            <RatingLabelPlaceholder
                              key={index}
                              index={index}
                              options={options}
                              option={options[index]}
                              isSelected={isSelected}
                              useMultiLangString={useMultiLangString}
                              onChange={() => {
                                setValue('answer', !isSelected ? index.toString() : null);
                                onChange?.({
                                  questionId: questionData.id,
                                  answer: !isSelected ? index : -1,
                                });
                              }}
                            />
                          );
                        })}
                      </>
                    ) : null}
                  </>
                )}
              />
            </RatingLabelContainer>
            <DefinitionContainer>
              <span>Definition</span>: Identifying problems, recognising important information.
              Systematically tackling a problem and making connections between data. Detecting
              possible causes of problems.
            </DefinitionContainer>
            {focusAreas.length ? (
              <FocusAreaSection
                level={{ label: { en_GB: 'Advanced', nl_NL: 'Advanced' }, focusAreas }}
                useMultiLangString={useMultiLangString}
                openFocusAreaModal={openFocusAreaModal}
              />
            ) : (
              <FocusAreaQuestionPlaceholder openFocusAreaModal={openFocusAreaModal} />
            )}
          </OldSkillQuestionContentWrapper>
          {questionData?.settings?.isCommentsAllowed && (
            <CommentSection
              formMethods={formMethods}
              isRequired={!$isNotApplicable.value && questionData?.settings?.isCommentsObligated}
              onChange={(comment) => onChange?.({ questionId: questionData.id, comment })}
              hasError={hasCommentError}
            />
          )}
        </>
      )}
      {showOtherRatings && displayAnswers && (
        <AllAnswers>
          <Trans>All answers</Trans>
          <Answers>
            <RatingAnswers
              question={question}
              questionData={questionData}
              useMultiLangString={useMultiLangString}
            />
          </Answers>
        </AllAnswers>
      )}
      {$openFocusAreaModal.value && (
        <FocusAreaModal
          options={focusAreaOptions}
          title={i18n._(t`Skill levels: Analytics`)}
          description={i18n._(
            t`Identifying problems, recognising important information. Systematically tackling a problem and making connections between data. Detecting possible causes of problems.`,
          )}
          selectedLevel={selectedLevel}
          onClose={$openFocusAreaModal.off}
          useMultiLangString={useMultiLangString}
        />
      )}
    </QuestionWrapper>
  );
};
