import React, { useState, useEffect } from 'react';

import { ROLES, REPORT_TYPES, REPORT_CHART_TYPES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardLineGraph from '~/components/Graphs/custom/DashboardLineGraph';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Tooltip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import emptyState from '~/assets/empty-state.webp';

import routes from '~/constants/routes';
import { getSelectedRole, getCustomReports } from '~/selectors/baseGetters';
import { getEngagementCharts } from '~/services/reports';
import { COLORS } from '~/styles';
import { getDateForTimeFrame } from '~/utils/reports';

import { Header, Title } from '../design';

import type { ICareerPlan, IReportCustomReport } from '@learned/types';

interface DetailsProps {
  careerPlan?: ICareerPlan;
}
export type TChartData = { key: string; value: number; deviation: number };

const Wrapper = styled.div`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
`;

const WaveCtr = styled.div`
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
`;

const AverageCtr = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  color: black;
`;

const ViewReportButtonWrapper = styled.div`
  margin-top: 22px;
  align-self: end;
`;

const PlaceholderWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 7px;
  flex-direction: column;
`;

const PerformanceSection = ({ careerPlan }: DetailsProps) => {
  const { i18n } = useLingui();
  const [isLoading, setIsLoading] = useState(true);
  const currentRole = useSelector(getSelectedRole);
  const [hasScore, setHasScore] = useState<boolean>(false);
  const [chartSeries, setChartSeries] = useState<TChartData[]>();
  const [reportTabId, setReportTabId] = useState<string | undefined>();
  const customReports = useSelector(getCustomReports) as IReportCustomReport[];
  const [ratingLabelsCount, setRatingLabelsCount] = useState<number | undefined>(undefined);
  const [latestCoachReviewScore, setLatestCoachReviewScore] = useState<{
    maxScore: string;
    score: string;
  }>();

  const getData = async () => {
    if (careerPlan && careerPlan.jobProfile) {
      try {
        setIsLoading(true);
        setHasScore(false);
        const overtimePayload = {
          viewAs: currentRole,
          reportType: REPORT_TYPES.PERFORMANCE,
          chartType: REPORT_CHART_TYPES.BY_MONTH,
          dateRange: getDateForTimeFrame('24'),
          filters: {
            jobs: [careerPlan?.jobProfile],
          },
        };

        const reviewScorePayload = {
          viewAs: currentRole,
          reportType: REPORT_TYPES.PERFORMANCE,
          dateRange: getDateForTimeFrame('24'),
          chartType: REPORT_CHART_TYPES.MEMBER_PERFORMANCE_SCORE,
          filters: {
            jobs: [careerPlan?.jobProfile],
          },
        };

        const [overtimeResponse, reviewScoreResponse] = await Promise.all([
          getEngagementCharts(overtimePayload),
          getEngagementCharts(reviewScorePayload),
        ]);

        if (reviewScoreResponse?.data?.scores?.length) {
          const latestScore = reviewScoreResponse.data.scores?.find(
            (sc) =>
              sc.memberId === careerPlan.createdFor && sc.jobProfileId === careerPlan.jobProfile,
          );

          setLatestCoachReviewScore({
            maxScore: latestScore?.maxScore || '0',
            score: latestScore?.score || '0',
          });
        }

        setRatingLabelsCount(overtimeResponse.data?.averageEngagement?.rlc || undefined);

        if (overtimeResponse.data?.averageEngagement?.series) {
          const dataSeries = overtimeResponse.data.averageEngagement.series?.map((s) => ({
            key: s.month,
            value: s.value,
            deviation: 0,
          }));

          setChartSeries(dataSeries);
        }

        if (overtimeResponse.data?.total > 0) {
          setHasScore(true);
        }

        if (customReports) {
          const tabId = customReports
            .find((cr) => cr.reportType === REPORT_TYPES.PERFORMANCE)
            ?.tabs.find(
              (tab) => tab.isDefault && tab.isPersonal && tab.name === 'Per theme over time',
            )?.tabId;
          if (tabId) {
            setReportTabId(tabId);
          }
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerPlan]);

  return (
    <Wrapper>
      <Header>
        <Title>
          <Trans>Performance</Trans>
        </Title>
      </Header>

      <div style={{ fontSize: '14px', color: COLORS.SUBTEXT }}>
        <Trans>
          How your coach(es) review your behavior, knowledge and reached results for this job
        </Trans>
      </div>

      <ShowSpinnerIfLoading loading={isLoading}>
        {!hasScore && (
          <PlaceholderWrapper>
            <img src={emptyState} alt={i18n._(t`No data found`)} width="130px" />
            <div style={{ fontSize: '14px', color: COLORS.SUBTEXT }}>
              <Trans>No data available</Trans>
            </div>
          </PlaceholderWrapper>
        )}
        {hasScore && (
          <>
            <Tooltip
              tooltip={<Trans>Last 24 months you had this job</Trans>}
              placement={TOOLTIP_PLACEMENTS.RIGHT}
            >
              <div
                style={{
                  marginTop: '15px',
                  fontSize: '14px',
                  color: COLORS.SUBTEXT,
                  width: 'max-content',
                }}
              >
                <Trans>Last 24 months</Trans>
              </div>
            </Tooltip>
            <WaveCtr>
              <DashboardLineGraph
                ratingLabelsCount={ratingLabelsCount}
                chartData={chartSeries as TChartData[]}
              />
              {latestCoachReviewScore?.score && (
                <Tooltip
                  tooltip={<Trans>Average rating based on the last review on this job.</Trans>}
                >
                  <AverageCtr>
                    {latestCoachReviewScore?.score}/{latestCoachReviewScore?.maxScore}
                  </AverageCtr>
                </Tooltip>
              )}
            </WaveCtr>
            {reportTabId && (
              <ViewReportButtonWrapper>
                <Button
                  variant={ButtonVariant.NAVIGATION_PRIMARY}
                  label={i18n._(t`View Report`)}
                  size={ButtonSize.MEDIUM}
                  onClick={() => {
                    routes.REPORTS_USER_CUSTOM_REPORT.go(
                      // @ts-ignore
                      { role: ROLES.USER },
                      {
                        reportType: REPORT_TYPES.PERFORMANCE,
                        reportId: reportTabId,
                      },
                    );
                  }}
                />
              </ViewReportButtonWrapper>
            )}
          </>
        )}
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
};

export default React.memo(PerformanceSection);
