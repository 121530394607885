import React from 'react';

import { Trans } from '@lingui/macro';

import { TSelectedLevel } from '~/pages/ReviewGiveFeedback/types';

import { ExpectedLevelTitle, FAContainer, FocusAreaLabel, LevelViewButton } from '../design';

interface IProps {
  level: {
    label: {
      en_GB: string;
      nl_NL: string;
    };
    focusAreas: {
      label: {
        en_GB: string;
        nl_NL: string;
      };
    }[];
  };
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  openFocusAreaModal?: (selectedFocusArea: TSelectedLevel | null) => void;
}

export const FocusAreaSection = ({ level, useMultiLangString, openFocusAreaModal }: IProps) => {
  const getMultiLangString = useMultiLangString();
  return (
    <FAContainer>
      <ExpectedLevelTitle>
        <Trans>{getMultiLangString(level.label || '')}</Trans>
      </ExpectedLevelTitle>
      {level.focusAreas.map((focusArea, index) => {
        return (
          <FocusAreaLabel key={index}>
            {`- ${getMultiLangString(focusArea.label || '')}`}
          </FocusAreaLabel>
        );
      })}
      {openFocusAreaModal && (
        <LevelViewButton onClick={openFocusAreaModal}>
          <Trans>View all levels</Trans>
        </LevelViewButton>
      )}
    </FAContainer>
  );
};
