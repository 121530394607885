import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';

import { DateInput } from '~/components/DateInput';
import { ICONS, Icon } from '~/components/Icon';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { Separator, SubTitle } from '../../../design';
import { IReviewSelfForm } from '../../../types';
import { DateRow, ExplanationText, SubTitleRow } from '../design';

interface NominatePeersSectionProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
  languageState: ILanguageStateReturn;
}

const NominatePeersSection = ({ formMethods }: NominatePeersSectionProps) => {
  const { i18n } = useLingui();
  const { trigger, watch, setValue, formState } = formMethods;

  const onChangeDate = (date: Date, isStartDate?: boolean) => {
    const key = isStartDate
      ? 'tasks.reviewPeerNominate.startDate'
      : 'tasks.reviewPeerNominate.endDate';
    setValue(key, date, { shouldDirty: true });
    setValue('settings.isAutoTimeline', false, { shouldDirty: true });
    formState.errors.tasks?.reviewPeerNominate?.startDate ||
    formState.errors.tasks?.reviewPeerNominate?.endDate
      ? trigger('tasks.reviewPeerNominate')
      : trigger(key);
  };

  const getFormattedDateToString = (isStartDate?: boolean) => {
    const key = isStartDate
      ? 'tasks.reviewPeerNominate.startDate'
      : 'tasks.reviewPeerNominate.endDate';
    const datePattern = 'DD-MM-YYYY';
    const watchDate = watch(key);

    if (watchDate) {
      return moment(watchDate).format(datePattern);
    }
    return '';
  };

  return (
    <>
      <Separator marginBottom="24px" marginTop="24px" />
      <SubTitleRow>
        <Icon icon={ICONS.NOMINATE_PEER} />
        <SubTitle>
          <Trans>Ask peers for input</Trans>
        </SubTitle>
      </SubTitleRow>
      <ExplanationText>
        <Trans>
          Because the review template contains questions for peers, employees will be given the task
          of asking peers for input.
        </Trans>
      </ExplanationText>
      <DateRow>
        <DateInput
          value={getFormattedDateToString(true)}
          label={i18n._(t`Start date`)}
          onChange={(date) => onChangeDate(date as Date, true)}
          error={formState.errors?.tasks?.reviewPeerNominate?.startDate?.message}
        />
        <DateInput
          value={getFormattedDateToString()}
          label={i18n._(t`Deadline`)}
          onChange={(date) => onChangeDate(date as Date)}
          error={formState.errors?.tasks?.reviewPeerNominate?.endDate?.message}
        />
      </DateRow>
    </>
  );
};

export { NominatePeersSection };
