import { useEffect, useState } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_TYPE, TASK_TYPE } from '@learned/constants';

import {
  groupQuestionsBasedOnType,
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionSkillCategoryGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { RATING_USER_TYPE } from '../types';

import type {
  IReviewRating,
  IReviewTheme,
  ITask,
  IUserReview,
  IUserReviewQuestion,
  IUserReviewQuestionGoalPlan,
  IUserReviewQuestionRating,
  IUserReviewQuestionText,
  WithExtends,
} from '@learned/types';

export type UserReview = Omit<IUserReview, 'userReviewQuestions'> & {
  tasks: ITask[];
  userReviewQuestions: IUserReviewQuestion[];
};

export type IUserReviewQuestionGrouped =
  | WithExtends<
      IUserReviewQuestionText & { reviewRatings?: IReviewRating[] },
      { theme?: IReviewTheme }
    >
  | WithExtends<
      IUserReviewQuestionRating & { reviewRatings?: IReviewRating[] },
      { theme?: IReviewTheme }
    >
  | IUserReviewQuestionGoalPlan
  | WithExtends<IUserReviewQuestionCustomSkillGrouped, { theme?: IReviewTheme }>
  | WithExtends<IUserReviewQuestionSkillCategoryGrouped, { theme?: IReviewTheme }>;

export const useReviewRatings = ({ userReview }: { userReview?: UserReview }) => {
  const [reviewRatings, setReviewRatings] = useState<IUserReviewQuestionGrouped[]>([]);

  const getReviewRatingType = (reviewTask: ITask) => {
    switch (reviewTask.type) {
      case TASK_TYPE.REVIEW_SELF_EVALUATE:
        return { reviewType: RATING_USER_TYPE.SELF, type: REVIEW_RATING_TYPE.SELF };
      case TASK_TYPE.REVIEW_COACH_EVALUATE:
        return { reviewType: RATING_USER_TYPE.COACH, type: REVIEW_RATING_TYPE.COACH };
      case TASK_TYPE.REVIEW_PEER_EVALUATE:
        return reviewTask?.userTo?.email
          ? { reviewType: RATING_USER_TYPE.EXTERNAL_PEER, type: REVIEW_RATING_TYPE.PEER }
          : { reviewType: RATING_USER_TYPE.PEER, type: REVIEW_RATING_TYPE.PEER };
    }
  };

  const getEmptyTextRatingQuestion = (
    question: IUserReviewQuestion & { reviewRatings?: IReviewRating[] },
    reviewTask: ITask,
  ) => {
    return {
      // we have to use -1 and '' to start with because null means user refused to answer
      answer: question.type === REVIEW_QUESTION_TYPES.RATING ? -1 : '',
      comment: null,
      createdBy: reviewTask.userTo,
      company: question.company,
      createdFor: reviewTask.userFrom,
      userReview: reviewTask.target,
      userReviewQuestion: question.id,
    };
  };

  const mapReviewRating = (
    question: IUserReviewQuestion & { reviewRatings?: IReviewRating[] },
    task: ITask,
  ) => {
    const rating = question.reviewRatings?.find((rating) => rating.task === task.id);
    const reviewRating = rating || getEmptyTextRatingQuestion(question, task);
    return {
      ...reviewRating,
      ...getReviewRatingType(task),
    };
  };

  const getTextReviewRatings = (
    question: IUserReviewQuestion & { reviewRatings?: IReviewRating[] },
    userReview: UserReview,
  ) => {
    const selfReviewTask = userReview.tasks.find(
      (task) => task.type === TASK_TYPE.REVIEW_SELF_EVALUATE,
    );
    let employeeReviews;
    if (selfReviewTask) {
      const reviewRating =
        question.reviewRatings?.find((rating) => rating.task === selfReviewTask?.id) ||
        getEmptyTextRatingQuestion(question, selfReviewTask);
      employeeReviews = { ...reviewRating, ...getReviewRatingType(selfReviewTask) };
    }

    const coachReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_COACH_EVALUATE,
    );
    const coachReviews = coachReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);

    const internalPeerReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_PEER_EVALUATE && task.userTo?.id,
    );
    const externalPeerReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_PEER_EVALUATE && task.userTo?.email,
    );
    const internalPeerReviews = internalPeerReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);
    const externalPeerReviews = externalPeerReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);
    return [
      employeeReviews,
      ...coachReviews,
      ...internalPeerReviews,
      ...externalPeerReviews,
    ].filter(Boolean);
  };

  const getAllReviewRatings = (userReview: UserReview) => {
    const questions = groupQuestionsBasedOnType(userReview);
    const ratings = userReview.userReviewQuestions.map((question) => {
      switch (question.type) {
        case REVIEW_QUESTION_TYPES.TEXT:
        case REVIEW_QUESTION_TYPES.RATING:
          return { ...question, reviewRatings: getTextReviewRatings(question, userReview) };
        case REVIEW_QUESTION_TYPES.GOAL_PLAN:
          return question;
        case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
          const skillCategory = question.settings.skillCategory;
          const skillCategoryQuestionIndex = questions.findIndex(
            (question) =>
              question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY &&
              question.skillCategory === skillCategory,
          );
          if (skillCategoryQuestionIndex === -1) {
            return;
          }

          const [skillCategoryQuestion] = questions.splice(skillCategoryQuestionIndex, 1);
          return skillCategoryQuestion;
        }
        case REVIEW_QUESTION_TYPES.CUSTOM_SKILL: {
          const skillCategory = question.settings.skillCategory;
          const skillCategoryQuestionIndex = questions.findIndex(
            (question) =>
              question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL &&
              question.skillCategory === skillCategory,
          );
          if (skillCategoryQuestionIndex === -1) {
            return;
          }

          const [skillCategoryQuestion] = questions.splice(skillCategoryQuestionIndex, 1);
          return skillCategoryQuestion;
        }
      }
    });
    return ratings.flat();
  };

  useEffect(() => {
    if (userReview) {
      const reviewRatings = getAllReviewRatings(userReview);
      setReviewRatings(reviewRatings.filter(Boolean) as IUserReviewQuestionGrouped[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReview]);

  return { reviewRatings };
};
