import { REVIEW_QUESTION_TYPES, REVIEW_RATING_STATUS } from '@learned/constants';

import type { UserReview } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/hooks/useReviewRatings';

import { AnswerType } from './types';

import type {
  IMultiLangString,
  IReviewRating,
  IUser,
  IUserReview,
  IUserReviewQuestionCustomSkill,
  IUserReviewQuestionCustomSkillWithSkill,
  IUserReviewQuestionSkillCategory,
  IUserReviewQuestionSkillCategoryWithJobProfile,
} from '@learned/types';

export type IUserReviewQuestionSkillCategoryGrouped = {
  type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY;
  skillCategory: string;
  name: IMultiLangString;
  description?: IMultiLangString;
  theme: string;

  skills: {
    skillId: string;
    questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
      reviewRatings?: IReviewRating[];
    })[];
  }[];
};

export type IUserReviewQuestionCustomSkillGrouped = {
  type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL;
  skillCategory: string;
  name: IMultiLangString;
  description?: IMultiLangString;
  theme: string;

  skills: {
    skillId: string;
    questions: (IUserReviewQuestionCustomSkillWithSkill & { reviewRatings?: IReviewRating[] })[];
  }[];
};

export function groupQuestionsBasedOnType(userReview: UserReview) {
  const groupedSkillCategoryQuestions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<
        string,
        Record<
          string,
          {
            questions: (IUserReviewQuestionSkillCategory & { reviewRatings?: IReviewRating[] })[];
            skillId: string;
          }
        >
      >,
      question,
    ) => {
      if (!question || question.type !== REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      // @ts-ignore
      const skill = question.settings.skill.id;
      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][skill] = acc[skillCategory][skill] ?? {
        skillId: skill,
        questions: [],
      };
      acc[skillCategory][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const groupedCustomSkillQuestions = userReview.userReviewQuestions.reduce(
    (
      acc: Record<
        string,
        Record<
          string,
          {
            questions: (IUserReviewQuestionCustomSkill & { reviewRatings?: IReviewRating[] })[];
            skillId: string;
          }
        >
      >,
      question,
    ) => {
      if (!question || question.type !== REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
        return acc;
      }
      const skillCategory = question.settings.skillCategory;
      const skill = question.settings.skill;
      acc[skillCategory] = acc[skillCategory] ?? {};
      acc[skillCategory][skill] = acc[skillCategory][skill] ?? {
        skillId: skill,
        questions: [],
      };
      acc[skillCategory][skill].questions.push(question);
      return acc;
    },
    {},
  );

  const seenSkillCategoriesForSkillCategory = new Set();
  const seenSkillCategoriesForCustomSkill = new Set();
  return userReview.userReviewQuestions.reduce(
    (
      acc: (IUserReviewQuestionSkillCategoryGrouped | IUserReviewQuestionCustomSkillGrouped)[],
      question,
    ) => {
      if (question && question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
        if (seenSkillCategoriesForSkillCategory.has(question.settings.skillCategory)) {
          return acc;
        } else {
          seenSkillCategoriesForSkillCategory.add(question.settings.skillCategory);
          acc.push({
            type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
            skillCategory: question.settings.skillCategory,
            skills: Object.values(groupedSkillCategoryQuestions[question.settings.skillCategory]),
            name: question.name,
            description: question.description,
            theme: question.theme,
          });
          return acc;
        }
      } else if (question && question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
        if (seenSkillCategoriesForCustomSkill.has(question.settings.skillCategory)) {
          return acc;
        } else {
          seenSkillCategoriesForCustomSkill.add(question.settings.skillCategory);
          acc.push({
            type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
            skillCategory: question.settings.skillCategory,
            skills: Object.values(groupedCustomSkillQuestions[question.settings.skillCategory]),
            name: question.name,
            description: question.description,
            theme: question.theme,
          });
          return acc;
        }
      }

      return acc;
    },
    [],
  );
}

export const mapRatingsList = (
  reviewRatings: (IReviewRating & {
    reviewType: string;
  })[],
) => {
  let isFirstAnswerFound = false;
  let answers: AnswerType[] = [];

  answers = reviewRatings.map((rating) => {
    let isOpened = false;
    if (!isFirstAnswerFound && rating.answer && rating.answer !== -1) {
      isOpened = true;
      isFirstAnswerFound = true;
    } else {
      isOpened = false;
    }
    return {
      ...rating,
      isOpened,
    };
  });

  if (!isFirstAnswerFound && answers[0]) {
    answers[0].isOpened = true;
  }

  return answers;
};

export const showCalibratedLabel = (
  rating: AnswerType,
  userReview: IUserReview,
  currentUser: IUser,
) => {
  const isValidUserRole = userReview.coaches.includes(currentUser.id) || currentUser.isAdmin;

  return (
    isValidUserRole &&
    rating.createdFor !== currentUser.id &&
    rating.status === REVIEW_RATING_STATUS.CALIBRATE
  );
};
