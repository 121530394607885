import React, { ReactNode } from 'react';

import isEmpty from 'lodash/isEmpty';

import { AnswerView, Container, Description, HeaderWrapper, ThemeTitle, Title } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IMultiLangString } from '@learned/types';

interface IQuestionWrapperProps {
  isZeroState: boolean;
  children?: ReactNode;
  questionNumber: number;
  themeName: string;
  question: {
    name: IMultiLangString;
    description?: IMultiLangString;
  };
}

export const QuestionWrapper = ({
  isZeroState,
  children,
  questionNumber,
  themeName,
  question,
}: IQuestionWrapperProps) => {
  const getMultiLangString = useMultiLangString();

  return (
    <Container>
      <HeaderWrapper $isZeroState={isZeroState}>
        <ThemeTitle>{`Question: ${questionNumber} | ${getMultiLangString(themeName)}`}</ThemeTitle>
        <Title>{getMultiLangString(question.name)}</Title>
        {!isEmpty(question.description) && (
          <Description>{getMultiLangString(question.description || '')}</Description>
        )}
      </HeaderWrapper>
      <AnswerView>{children}</AnswerView>
    </Container>
  );
};
