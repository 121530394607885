import React from 'react';

import styled from 'styled-components';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { COLORS } from '~/styles';

import type { IMultiLangString } from '@learned/types';

interface IProps {
  option: {
    label: IMultiLangString;
  };
  isSelected?: boolean;
  onChange?: () => void;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

const Container = styled.div`
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;

  & .selected {
    background: color-mix(in srgb, ${COLORS.COMPANY}, transparent 90%);
    color: ${COLORS.COMPANY};
    font-weight: 600;
  }
`;

const LabelWrapper = styled.div<{ $isSelected: boolean }>`
  min-width: 100px;
  height: 50px;
  background-color: ${COLORS.BG_LIST};
  flex-base: 130px;
  flex-grow: 1;
  text-align: center;
  align-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${COLORS.BG_LIST};
  color: ${COLORS.ACTIVITY_GREY};

  &:hover {
    background: color-mix(in srgb, ${COLORS.COMPANY_HOVER}, transparent 95%);
    font-size: 14px;
    line-height: 1.36;
    text-align: center;
    color: ${COLORS.COMPANY};
  }
`;

const Label = styled.span`
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.5em;
  line-height: 1.25em;
`;

const RatingLabel = ({ option, isSelected, onChange, useMultiLangString }: IProps) => {
  const getMultiLangString = useMultiLangString();

  return (
    <Tooltip size={TOOLTIP_SIZES.DEFAULT} tooltip={getMultiLangString(option.label || {})}>
      <Container>
        <LabelWrapper
          $isSelected={!!isSelected}
          onClick={() => onChange?.()}
          className={isSelected ? 'selected' : ''}
        >
          <Label>{getMultiLangString(option.label || {})}</Label>
        </LabelWrapper>
      </Container>
    </Tooltip>
  );
};

export { RatingLabel };
