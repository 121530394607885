// Following mock data will be removed with the API integration
export const MockData = [
  {
    label: { en_GB: 'Novice', nl_NL: 'Novice' },
    isExpectedLevel: false,
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Intermediate', nl_NL: 'Intermediate' },
    isExpectedLevel: false,
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Professional', nl_NL: 'Professional' },
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Advanced', nl_NL: 'Advanced' },
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Expert', nl_NL: 'Expert' },
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Novice', nl_NL: 'Novice' },
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Intermediate', nl_NL: 'Intermediate' },
    isExpectedLevel: false,
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Professional', nl_NL: 'Professional' },
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Advanced', nl_NL: 'Advanced' },
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
  {
    label: { en_GB: 'Expert', nl_NL: 'Expert' },
    isExpectedLevel: true,
    focusAreas: [
      {
        label: {
          en_GB:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
          nl_NL:
            'Makes a distinction between functional inforamtion and interpretations or assumptions.',
        },
      },
      {
        label: {
          en_GB: 'Calculates long-term consequences.',
          nl_NL: 'Calculates long-term consequences.',
        },
      },
    ],
  },
];
